import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  About,
  Contactus,
  Feature,
  Footer,
  Header,
  Membership,
  MostViewed,
  Navbar,
  ProductSlider,
  Slider,
} from "../components";
import ICO from "../components/ICO";
import PATH from "../constants/PathContstant";
import { showMyPlan, userProfile } from "../redux/profileReducer";

const Home = () => {
  const dispatch = useDispatch();
  const { LoginUser_Data } = useSelector((store) => store.authStore);
  useEffect(() => {
    if (LoginUser_Data) {
      dispatch(userProfile());
      dispatch(showMyPlan());
    }
  }, [LoginUser_Data]);
  return (
    <div>
      <Header />
      <Feature />
      <div id={PATH.PRODUCTID}>
        <ProductSlider />
      </div>
      <div id={PATH.MEMBERSHIPID}>
        <Membership />
      </div>
      <div id={PATH.ABOUTID}>
        <About />
      </div>
      <div id={PATH.ABOUTID}>
        <MostViewed />
      </div>
      <div id={PATH.ICO}>
        <ICO />
      </div>

      <div id={PATH.CONTACTID}>
        <Contactus />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
