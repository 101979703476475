import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { blue, green } from "@mui/material/colors";
import { ButtonDarkContained } from "../styles/styledComponent";
import { useNavigate } from "react-router-dom";
import PATH from "../constants/PathContstant";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const Membership = () => {
  const navigate = useNavigate();
  const { UserProfile_Data, ShowMyPlan_Data } = useSelector(
    (store) => store.profileStore
  );
  const handleNavigate = (price, type) => {
    if (UserProfile_Data) {
      const d = ShowMyPlan_Data?.data?.filter(
        (item) => item?.filter === "membership"
      );
      if (d?.length === 0) {
        navigate(`${PATH.BUYPLAN}/${price}/${type}/membership`);
      } else {
        toast.warning("You have already buy a plan.");
      }
    } else {
      navigate(PATH.LOGIN);
    }
  };
  const memberPriceData = [
    {
      id: 1,
      name: "BASIC",
      price: "$25",
      rupees: 2100,
      text: [
        "Art Auction Profit Sharing Cap 40%",
        "Membership Can not Be Upgraded",
        "Daily Limit of Uploaded Art Images 5",
      ],
      ln: "linear-gradient(to right,#de5c70 0%,#e98c5d 100%)",
    },
    {
      id: 2,
      name: "PREMIUM",
      price: "$50",
      rupees: 4100,
      text: [
        "Art Auction Profit Sharing Cap 55%",
        "Membership Can not be Upgraded",
        "Daily Limit of Uploaded Art Images 10",
        "Team Referral Auction Sharing 5%",
      ],
      ln: "linear-gradient(to right,#4057e3 0%,#0091ff 100%)",
    },
    {
      id: 3,
      name: "SUPER PREMIUM",
      price: "$100",
      rupees: 8500,
      text: [
        "Premium Feature of Uploading HD Images",
        "Art Auction Profit Sharing Cap 70%",
        "Membership Can not be Upgraded",
        "Daily limit of Uploaded Art Images 25",
        "Team Referral Auction Sharing 7.5%",
      ],
      ln: "linear-gradient(to right,#05b9c8 0%,#25f398 100%)",
    },
    {
      id: 4,
      name: "PARTNER",
      price: "$200",
      rupees: 15000,
      text: [
        "Art Auction Profit Sharing 85%",
        "Membership Can not be Upgraded",
        "Team Referral Auction Sharing 10%",
        "Unlimited HD Images Uploaded",
      ],
      ln: "linear-gradient(to right,#d63f87 0%,#f9606a 100%)",
    },
  ];
  return (
    <Box
      sx={{ background: "linear-gradient(to right, #37125c 0%, #d9caf8 100%)" }}
    >
      <Container maxWidth="xl">
        <Box sx={{ py: 2 }}>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{ color: "#fff", fontSize: "40px" }}
          >
            Membership Plan
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{ color: "#ffffffba" }}
          >
            Join Us now to participate to encash your art work into your wealth.
          </Typography>
          <Stack
            direction="row"
            gap={2}
            flexWrap="wrap"
            justifyContent="center"
            sx={{ pb: 1, pt: 3 }}
          >
            {memberPriceData.map((item, itemIndex) => (
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{
                  boxShadow: itemIndex === 1 ? 15 : 3,
                  background: item?.ln,
                  minHeight: "400px",
                  width: "300px",
                  borderRadius: "10px",
                  padding: "20px",
                  borderColor: itemIndex === 1 ? "white" : null,
                  borderWidth: itemIndex === 1 ? "2px" : null,
                  borderStyle: itemIndex === 1 ? "solid" : null,
                }}
                key={itemIndex}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#fff",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="h4" sx={{ mt: 0.2, color: "#fff" }}>
                    {item.price}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "rgba(255,255,255,0.8)" }}
                  >
                    For India {item.rupees}
                  </Typography>
                  <Divider
                    sx={{
                      mb: 1,
                      backgroundColor: "rgba(255,255,255,0.7)",
                    }}
                  />
                  {item.text.map((text, textIndex) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={textIndex}
                      gap={2}
                      sx={{ py: 0.6 }}
                    >
                      <CheckIcon sx={{ color: green[500] }} />
                      <Typography variant="body1" sx={{ color: "#fff" }}>
                        {text}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <ButtonDarkContained
                    onClick={() => handleNavigate(item.rupees, item.id)}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    Buy Now
                  </ButtonDarkContained>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Membership;
