const PATH = {
  HOME: "/",
  ABOUTID: "about",
  PRODUCTID: "product",
  ICO: "nft",
  CONTACTID: "contact",
  MEMBERSHIPID: "membership",
  LOGIN: "/login",
  REGISTER: "/register",
  MYPLAN: "/my-plan",
  TRANSACTION: "/transaction",
  BUYPLAN: "/plan-buy",
  PROFILE: "/profile",
};

export default PATH;
