import React from "react";

const Transaction = () => {
  return (
    <div>
      <p>Transaction</p>
    </div>
  );
};

export default Transaction;
