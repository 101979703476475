import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../api/axiosInstance";
import { clearProfile } from "./profileReducer";

export const registerUser = createAsyncThunk("RegisterUser", async (body) => {
  try {
    const { data } = await axiosInstance.post("/registerUser", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});

export const changePassword = createAsyncThunk(
  "changePassword",
  async (body, {}) => {
    try {
      const { data } = await axiosInstance.post("/changeuserpassword", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const loginUser = createAsyncThunk("loginUser", async (body, {}) => {
  try {
    const { data } = await axiosInstance.post("/login", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    console.log(error, "in error");
    return { data, status };
  }
});
export const sendOtp = createAsyncThunk("sendOtp", async (body) => {
  try {
    const { data } = await axiosInstance.post("/sendotp", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const verifyOtp = createAsyncThunk("verifyOtp", async (body) => {
  try {
    const { data } = await axiosInstance.post("/verifyotp", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;

    return { data, status };
  }
});
export const logOut = createAsyncThunk("logOut", async (body, { dispatch }) => {
  dispatch(clearProfile());
  return;
});

const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    changePassword_isLoading: false,
    changePassword_Data: "",
    changePassword_isError: "",

    RegisterUser_isLoading: false,
    RegisterUser_Data: "",
    RegisterUser_isError: "",

    LoginUser_isLoading: false,
    LoginUser_Data: "",
    LoginUser_isError: "",

    sendOtp_isLoading: false,
    sendOtp_Data: "",
    sendOtp_isError: "",

    verifyOtp_isLoading: false,
    verifyOtp_Data: "",
    verifyOtp_isError: "",
  },
  reducers: {
    clearChangePassword: (state) => {
      state.changePassword_isLoading = false;
      state.changePassword_Data = "";
      state.changePassword_isError = "";
    },
    clearRegister: (state) => {
      state.RegisterUser_isLoading = false;
      state.RegisterUser_Data = "";
      state.RegisterUser_isError = "";
    },
    clearSendOtp: (state) => {
      state.sendOtp_isLoading = false;
      state.sendOtp_Data = "";
      state.sendOtp_isError = "";
    },
    clearVerifyOtp: (state) => {
      state.verifyOtp_isLoading = false;
      state.verifyOtp_Data = "";
      state.verifyOtp_isError = "";
    },
    setToken: (state, actions) => {
      state.LoginUser_Data = actions.payload;
    },
  },
  extraReducers: {
    [changePassword.pending]: (state) => {
      state.changePassword_isLoading = true;
    },
    [changePassword.fulfilled]: (state, actions) => {
      state.changePassword_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.changePassword_isError = data;
      } else {
        state.changePassword_Data = data;
        state.changePassword_isError = "";
      }
    },
    [changePassword.rejected]: (state) => {
      state.changePassword_Loading = false;
    },
    [registerUser.pending]: (state) => {
      state.RegisterUser_isLoading = true;
    },
    [registerUser.fulfilled]: (state, actions) => {
      state.RegisterUser_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.RegisterUser_isError = data;
      } else {
        state.RegisterUser_Data = data;
        state.RegisterUser_isError = "";
      }
    },
    [registerUser.rejected]: (state) => {
      state.RegisterUser_Loading = false;
    },
    [sendOtp.pending]: (state) => {
      state.sendOtp_isLoading = true;
    },
    [sendOtp.fulfilled]: (state, actions) => {
      state.sendOtp_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.sendOtp_isError = data;
      } else {
        state.sendOtp_Data = data;
        state.sendOtp_isError = "";
      }
    },
    [sendOtp.rejected]: (state) => {
      state.sendOtp_Loading = false;
    },
    [verifyOtp.pending]: (state) => {
      state.verifyOtp_isLoading = true;
    },
    [verifyOtp.fulfilled]: (state, actions) => {
      state.verifyOtp_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.verifyOtp_isError = data;
      } else {
        state.verifyOtp_Data = data;
        state.verifyOtp_isError = "";
      }
    },
    [verifyOtp.rejected]: (state) => {
      state.verifyOtp_Loading = false;
    },

    [loginUser.pending]: (state) => {
      state.LoginUser_isLoading = true;
    },
    [loginUser.fulfilled]: (state, actions) => {
      state.LoginUser_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.LoginUser_isError = data;
        toast.error("Wrong Crendtial");
      } else {
        localStorage.setItem("authTokenArtAura", JSON.stringify(data));
        state.LoginUser_Data = data;
        state.LoginUser_isError = "";
        toast.success("Login Successfully");
      }
    },
    [loginUser.rejected]: (state) => {
      state.LoginUser_isLoading = false;
    },

    [logOut.fulfilled]: (state) => {
      state.LoginUser_isLoading = false;
      state.LoginUser_Data = "";
      state.LoginUser_isError = "";
      localStorage.removeItem("authTokenArtAura");
    },
  },
});
export default AuthSlice.reducer;
export const {
  clearRegister,
  setToken,
  clearChangePassword,
  clearSendOtp,
  clearVerifyOtp,
} = AuthSlice.actions;
