import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import PATH from "../constants/PathContstant";
import {
  ButtonLightContained,
  CustomTextField,
  StyledLinkId,
  Text1,
} from "../styles/styledComponent";

const Footer = () => {
  const goToViolation = (id) => {
    const violation = document.getElementById(id);
    console.log(violation.offsetTop);
    window.scrollTo({
      top: violation.offsetTop - 80,
      behavior: "smooth",
    });
  };
  const onClickHome = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Box sx={{ backgroundColor: "#fff" }}>
        <Container maxWidth="lg">
          <Stack direction={{ xs: "column", md: "row" }} sx={{ py: 2 }}>
            {/* Logo Section  */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ flex: 1, py: { xs: 2, md: null } }}
            >
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={onClickHome}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: (theme) => theme.customColors.white,
                    fontWeight: 600,
                  }}
                >
                  Art
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    backgroundColor: (theme) => theme.customColors.white,
                    fontWeight: 600,
                    borderRadius: "5px",
                    m: 0,
                    px: 0.3,
                    lineHeight: 0.9,
                    color: "#fff",
                  }}
                >
                  Auraa
                </Typography>
              </Stack>
            </Stack>
            {/* Navigation Section */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ flex: 1, py: { xs: 2, md: null } }}
            >
              <Stack direction="column" gap={1}>
                <StyledLinkId onClick={onClickHome}>Home</StyledLinkId>
                <StyledLinkId onClick={() => goToViolation(PATH.ABOUTID)}>
                  About
                </StyledLinkId>
                <StyledLinkId onClick={() => goToViolation(PATH.PRODUCTID)}>
                  Product
                </StyledLinkId>
                <StyledLinkId onClick={() => goToViolation(PATH.CONTACTID)}>
                  Contact
                </StyledLinkId>
              </Stack>
            </Stack>
            {/* Newsletter Section */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ flex: 1, py: { xs: 2, md: null } }}
            >
              <Box>
                <Text1 sx={{ fontSize: "20px" }}>Newsletter</Text1>
                <Stack direction="row" alignItems="center" gap={1}>
                  <CustomTextField size="small" placeholder="john@gmail.com" />
                  <ButtonLightContained sx={{ mt: "-10px" }} size="large">
                    Subscribe
                  </ButtonLightContained>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Container>
        <Typography
          sx={{
            color: "rgba(255,255,255,0.7)",
            textAlign: "center",
            py: 1,
            backgroundColor: (theme) => theme.customColors.background1,
          }}
          variant="body1"
        >
          All Copyright &#169; Reserved to ArtAura.
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
