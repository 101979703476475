import { Box, Button, styled, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "10px",
  backgroundColor: "#fff",
  borderRadius: "4px",
  "& label.Mui-focused": {
    color: theme.customColors.white,
  },
  "& .MuiOutlinedInput-root": {
    color: theme.customColors.white,
    "& fieldset": {
      borderColor: theme.customColors.whiteLight,
    },
    "&:hover fieldset": {
      borderColor: theme.customColors.white,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.customColors.white,
    },
  },
}));

export const RegisterCustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "5px",
  backgroundColor: theme.customColors.black,
  "& label.Mui-focused": {
    color: theme.customColors.white,
  },
  "& .MuiOutlinedInput-root": {
    color: theme.customColors.whiteLight,
    "& fieldset": {
      borderColor: theme.customColors.whiteLight,
    },
    "&:hover fieldset": {
      borderColor: theme.customColors.white,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.customColors.white,
    },
  },
}));
export const Text1 = styled(Typography)(({ theme }) => ({
  marginBottom: 0.5,
  color: theme.customColors.whiteLight,
}));
export const AboutText = styled(Typography)(({ theme }) => ({
  color: theme.customColors.whiteLight,
  textAlign: "justify",
}));
export const StyledLink = styled(Link)(({ theme }) => ({
  color: "#9567f7",
  fontSize: "18px",
  fontWeight: 500,
}));
export const StyledLinkId = styled(Typography)(({ theme }) => ({
  color: "#9567f7",
  fontSize: "18px",
  fontWeight: 500,
  cursor: "pointer",
}));
export const ButtonDarkContained = styled(Button)(({ theme }) => ({
  backgroundColor: "#9567f7",
  color: "#fff",
  transition: "all 0.1s",
  "&:hover": {
    backgroundColor: "#9567f7",
    transform: "scale(1.02)",
    transition: "all 0.1s",
  },
}));
export const ButtonLightContained = styled(Button)(({ theme }) => ({
  backgroundColor: "#9567f7",
  color: "#fff",
  boxShadow: theme.shadows[15],
  "&:hover": {
    color: "#fff",
    backgroundColor: '"#9567f7"',
    boxShadow: theme.shadows[15],
    transform: "scale(1.02)",
  },
}));
export const ButtonOutlined = styled(Button)(({ theme }) => ({
  color: theme.customColors.whiteLight,
  borderColor: theme.customColors.whiteLight,
  "&:hover": {
    color: theme.customColors.white,
    borderColor: theme.customColors.white,
    transform: "scale(1.02)",
  },
}));
export const NavHeadText = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "30px",
  fontWeight: "700",
  color: theme.customColors.white,
  transition: "all .2s",
}));
export const FeatureBox = styled(Box)(({ theme }) => ({}));
