import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import Navbar from "./Navbar";
//#9567f7
const Wrapper = styled(Box)`
  position: relative;
  z-index: 1;
  background-image: linear-gradient(
      180deg,
      rgb(13 15 45 / 40%),
      rgb(16 18 48 / 50%)
    ),
    url(/banner-home.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .banner_img {
  }
`;
const Header = () => {
  return (
    <Wrapper>
      <Navbar />
      <Box className="hero-section">
        <Container maxWidth="xl" sx={{ pt: 3 }}>
          <Grid container sx={{ px: "2vw" }}>
            <Grid
              item
              xs={12}
              md={6}
              justifyContent="center"
              direction="column"
              display="flex"
            >
              <Typography
                sx={{ color: "#fff", fontSize: { xs: "1.2rem", md: "2rem" } }}
              >
                Grow your income in a rapid way
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "1.7rem", md: "2.5rem" },
                  textTransform: "uppercase",
                  fontWeight: 600,
                  my: 2,
                }}
              >
                Welcome to Art Auraa
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "1rem", md: "1.5rem" },
                }}
              >
                Art Auraa held and showed me a different world of future it was
                an amazing imagination.
              </Typography>
              <Box sx={{ pt: 5 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#9567f7",
                    borderRadius: "2rem",
                    py: 1.5,
                    boxShadow: "0 3px 24px rgb(0 0 0 / 10%)",
                    "&:hover": {
                      backgroundColor: "#9567f7",
                    },
                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ height: "100%", width: "100%" }}>
                <img
                  alt="hero-image"
                  src="./hero.png"
                  style={{
                    height: "95%",
                    width: "95%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Wrapper>
  );
};

export default Header;
