import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { blue } from "@mui/material/colors";
const Card = ({ item }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        cursor: "pointer",
        boxShadow: 3,
      }}
    >
      <img
        src={item.img}
        alt="productImage"
        style={{ height: "250px", width: "100%", objectFit: "cover" }}
      />
      <Typography
        sx={{
          py: 2,
          textAlign: "center",
          color: (theme) => theme.customColors.whiteLight,
        }}
        variant="h6"
      >
        {item.name}
      </Typography>
    </Box>
  );
};
const ProductSlider = () => {
  const productData = [
    {
      name: "The Madness of Lavapiese",
      img: "/15.jpeg",
      price: 3000,
    },
    {
      name: "Creusa Jade",
      img: "/14.jpeg",
      price: 1000,
    },
    {
      name: "Cat Sunset",
      img: "/9.jpeg",
      price: 2000,
    },
    {
      name: "The River is Wide",
      img: "/12.jpeg",
      price: 2000,
    },
    {
      name: "You and Me",
      img: "/4.jpeg",
      price: 2000,
    },
    {
      name: "Night Dreams",
      img: "/17.jpeg",
      price: 2000,
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#f6f6f6", pt: 2 }}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ color: (theme) => theme.customColors.white, fontSize: "40px" }}
        >
          Products
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: (theme) => theme.customColors.whiteLight }}
        >
          This is our most recommendable product base on user perchage.
        </Typography>
        <Box sx={{ py: 3 }}>
          <Swiper
            navigation={true}
            spaceBetween={25}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            style={{
              paddingBottom: "20px",
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
            }}
            modules={[Navigation, Autoplay]}
          >
            {productData.map((item, index) => (
              <SwiperSlide key={index}>
                <Card item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default ProductSlider;
