import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import { ButtonDarkContained } from "../styles/styledComponent";
import { useNavigate } from "react-router-dom";
import PATH from "../constants/PathContstant";

const ICO = () => {
  const navigate = useNavigate();
  const handleNavigate = (price, type) => {
    navigate(`${PATH.BUYPLAN}/${price}/${type}/nft`);
  };
  const memberPriceData = [
    {
      id: 1,
      img: "/8.jpeg",
      name: "1 Hub",
      price: "$1160",
      rupees: 95000,
      text: [
        "1 HUB values 100 Art(NFT Holding)",
        "1 Art (NFT Holding) values 10$",
        "20 Month Staking",
        "Pay 2 times with Rs 95000 scanner",
      ],
      ln: "linear-gradient(to right,#7e2afc 0%,#a250f2 100%)",
    },
    {
      id: 2,
      name: "1/2 hub",
      img: "/2.jpeg",
      price: "$865",
      rupees: 71000,
      text: [
        "1/2 HUB values 50 Art (NFT Holding)",
        "1 Art (NFT Holding) values 10$",
        "NFT Collectible",
        "20 Month Staking",
      ],
      ln: "linear-gradient(to right,#de5c70 0%,#e98c5d 100%)",
    },
    {
      id: 3,
      name: "1/4 hub",
      img: "/3.jpeg",
      price: "$793",
      rupees: 65000,
      text: [
        "1/4 HUB values 25 Art (NFT Holdings)",
        "1 Art (NFT Holding) values 10$",
        "NFT Collectible",
        "Depend Upon Your NFT Collection",
        "20 Month Staking",
      ],
      ln: "linear-gradient(to right,#8628c1 0%,#cd2cac 100%)",
    },
    {
      id: 4,
      name: "1/8 hub",
      img: "/10.jpeg",
      price: "$507",
      rupees: 41500,
      text: [
        "1/8 HUB values 12 Art (NFT Holding)",
        "1 Art (NFT Holding) values 10$",
        "NFT Collectible",
        "Depend upon your NFT Collection",
        "20 Month staking",
      ],
      ln: "linear-gradient(to right,#f17f04 0%,#e69d50 100%)",
    },
    {
      id: 5,
      name: "1/1 hub",
      price: "$320",
      img: "/13.jpeg",
      rupees: 26200,
      text: [
        "1/10 HUB values 10 Art (NFT Holding)",
        "1 Art (NFT Holding) values 10$",
        "NFT Collectible",
        "Depend upon your NFT Collection",
        "20 Month staking",
      ],
      ln: "linear-gradient(to right,#076fb3 0%,#19c3cc 100%)",
    },
    {
      id: 6,
      name: "1/12.5 hub",
      price: "$164",
      img: "/6.jpeg",
      rupees: 13500,
      text: [
        "1/12.5 HUB values 7.5 Art (NFT Holding)",
        "1 Art (NFT Holding) values 10$",
        "NFT Collectible",
        "Depend upon your NFT Collection",
        "20 Month staking",
      ],
      ln: "linear-gradient(to right,#d63f87 0%,#f9606a 100%)",
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Container maxWidth="xl">
        <Box sx={{ py: 2 }}>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              color: (theme) => theme.customColors.white,
              fontSize: "40px",
            }}
          >
            Art Auraa NFT
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{ color: (theme) => theme.customColors.whiteLight }}
          >
            Art Auraa launches his own NFT and now Into the Business for
            Metaverse <br /> Art Auraa is in process to launch his NFT on
            respective blockchains like ETH & BEP20.
          </Typography>
          <Stack
            direction="row"
            gap={2}
            flexWrap="wrap"
            justifyContent="center"
            sx={{ pb: 1, pt: 3, maxWidth: "1050px", m: "auto" }}
          >
            {memberPriceData.map((item, itemIndex) => (
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{
                  boxShadow: itemIndex === 1 ? 15 : 3,
                  background: item?.ln,
                  minHeight: "400px",
                  width: "330px",
                  borderRadius: "10px",
                  borderColor: itemIndex === 1 ? "white" : null,
                  borderWidth: itemIndex === 1 ? "2px" : null,
                  borderStyle: itemIndex === 1 ? "solid" : null,
                }}
                key={itemIndex}
              >
                <img
                  alt="nft"
                  src={item.img}
                  style={{
                    height: "250px",
                    width: "100%",
                    objectFit: "cover",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                />
                <Box sx={{ padding: "20px" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#fff",
                      textTransform: "uppercase",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="h4" sx={{ my: 0.7, color: "#fff" }}>
                    {item.price}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "rgba(255,255,255,0.6)" }}
                  >
                    For India {item.rupees}
                  </Typography>
                  <Divider
                    sx={{
                      mb: 1,
                      backgroundColor: "rgba(255,255,255,0.6)",
                    }}
                  />
                  {item.text.map((text, textIndex) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={textIndex}
                      gap={2}
                      sx={{ py: 0.6 }}
                    >
                      <CheckIcon sx={{ color: green[500] }} />
                      <Typography variant="body1" sx={{ color: "#fff" }}>
                        {text}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
                <Box sx={{ mt: 2, padding: "20px" }}>
                  <ButtonDarkContained
                    onClick={() => handleNavigate(item.rupees, item.id)}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    Buy Now
                  </ButtonDarkContained>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default ICO;
