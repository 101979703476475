import { createTheme, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "./redux/store";
import Routes from "./routes/Routes";
import "./styles/App.css";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import ModalImage from "./assets/images/modalImage.jpeg";
const CustomModal = ({ open, setState }) => {
  return (
    <Drawer
      anchor="top"
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        widht: "100%",
      }}
      PaperProps={{
        sx: {
          width: { xs: "97%", md: "50%" },
          backgroundColor: "transparent",
          m: "auto",
          marginTop: "6rem",
          borderRadius: ".3rem",
          overflow: "auto",
          maxHeight: { xs: "98vh", md: "80vh" },
        },
      }}
      hideBackdrop={false}
      onClose={() => setState((prev) => ({ ...prev, modal: false }))}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <img src={ModalImage} style={{ height: "100%", width: "100%" }} />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 5,
          }}
        >
          <IconButton
            onClick={() => setState((prev) => ({ ...prev, modal: false }))}
          >
            <CloseIcon size="small" sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

function App() {
  const theme = createTheme({
    customColors: {
      background1: "#191c24",

      textColor: "#6c7293",
      white: "rgba(127, 17, 224, 1)",
      black: "#000",
      whiteLight: "rgba(127, 17, 224, 0.7)",
      whiteLight2: "rgba(127, 17, 224, 0.6)",
    },
    typography: {
      fontFamily: [
        "Nunito",
        "sans-serif",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  const [state, setState] = useState({
    modal: false,
  });
  useEffect(() => {
    setTimeout(() => {
      setState((prev) => ({ ...prev, modal: true }));
    }, 3000);
  }, []);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <CustomModal open={state.modal} setState={setState} />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={true}
          theme="colored"
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
