import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import profileReducer from "./profileReducer";

const store = configureStore({
  reducer: {
    authStore: auth,
    profileStore: profileReducer,
  },
});
export default store;
