import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Footer } from "../components";

import PATH from "../constants/PathContstant";
import { logOut, setToken } from "../redux/auth";
import { userProfile } from "../redux/profileReducer";
import { BuyPlan, Home, Login, Register } from "../screens";
import MyPlan from "../screens/MyPlan";
import MyProfile from "../screens/MyProfile";
import Transaction from "../screens/Transaction";
const RootRoutes = () => {
  const [login, setLogin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dt = new Date();
  const token = localStorage.getItem("authTokenArtAura");
  useEffect(() => {
    if (token) {
      const parseToken = JSON.parse(token);
      const { exp } = jwtDecode(parseToken?.access_token);
      if (dt.getTime() > exp * 1000) {
        dispatch(logOut());
      } else {
        dispatch(setToken(parseToken));
        setLogin(true);
      }
    }
  }, []);
  return (
    <>
      <Routes>
        <Route element={<Home />} path={PATH.HOME} />
        <Route element={<Login />} path={PATH.LOGIN} />
        <Route element={<Register />} path={PATH.REGISTER} />
        <Route element={<MyPlan />} path={PATH.MYPLAN} />
        <Route element={<MyProfile />} path={PATH.PROFILE} />
        <Route element={<Transaction />} path={`${PATH.TRANSACTION}/:amount`} />
        <Route
          element={<BuyPlan />}
          path={`${PATH.BUYPLAN}/:price/:type/:filter`}
        />
      </Routes>
    </>
  );
};

export default RootRoutes;
