import React, { useState } from "react";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PATH from "../constants/PathContstant";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import InventoryIcon from "@mui/icons-material/Inventory";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  ButtonDarkContained,
  ButtonLightContained,
  ButtonOutlined,
  StyledLinkId,
} from "../styles/styledComponent";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/auth";
import NavUserData from "./NavUserData";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import FileSaver from "file-saver";
import circular from "../assets/images/nft-revolution.pdf";
const Logo = ({ onClickHome }) => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={onClickHome}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#fff",
          fontWeight: 600,
        }}
      >
        Art
      </Typography>
      <Typography
        variant="h4"
        sx={{
          backgroundColor: "#9567f7",
          fontWeight: 600,
          borderRadius: "5px",
          m: 0,
          px: 0.3,
          lineHeight: 0.9,
          color: "#fff",
        }}
      >
        Auraa
      </Typography>
    </Stack>
  );
};
const ScrollLogo = ({ onClickHome }) => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={onClickHome}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#9567f7",
          fontWeight: 600,
        }}
      >
        Art
      </Typography>
      <Typography
        variant="h4"
        sx={{
          backgroundColor: "#9567f7",
          fontWeight: 600,
          borderRadius: "5px",
          m: 0,
          px: 0.3,
          lineHeight: 0.9,
          color: "#fff",
        }}
      >
        Auraa
      </Typography>
    </Stack>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { LoginUser_Data } = useSelector((store) => store.authStore);
  const { UserProfile_Data } = useSelector((store) => store.profileStore);
  const [state, setState] = useState({
    drawer: false,
    scroll: false,
  });

  window.addEventListener("scroll", () => {
    if (window.scrollY > 80) {
      if (!state.scroll) {
        setState({ ...state, scroll: true });
      }
    } else {
      if (state.scroll) {
        setState({ ...state, scroll: false });
      }
    }
  });

  const goToViolation = (id) => {
    const violation = document.getElementById(id);

    window.scrollTo({
      top: violation.offsetTop - 80,
      behavior: "smooth",
    });
  };
  const goToViolationDrawer = (id) => {
    setState({ ...state, drawer: false });
    const violation = document.getElementById(id);
    console.log(violation.offsetTop);
    window.scrollTo({
      top: violation.offsetTop - 80,
      behavior: "smooth",
    });
  };

  const onClickHome = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onClickHomeDrawer = () => {
    setState({ ...state, drawer: false });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLogout = () => {
    dispatch(logOut());
    setState({ ...state, drawer: false });
  };
  const handleDownload = () => {
    FileSaver.saveAs(circular, circular.pdf);
  };
  return (
    <div>
      <Drawer
        open={state.drawer}
        onClose={() => setState({ ...state, drawer: false })}
        PaperProps={{
          sx: {
            width: "250px",
            backgroundColor: "#fff",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            p: 2,
            boxShadow: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <ScrollLogo onClickHome={onClickHomeDrawer} />
            </Box>

            <Box
              sx={{
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setState({ ...state, drawer: false })}
            >
              <CloseIcon fontSize="2rem" />
            </Box>
          </Stack>
        </Box>
        <Stack
          sx={{ height: "100%" }}
          direction="column"
          justifyContent="space-between"
        >
          <Box sx={{ mt: 3 }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton alignItems="center" onClick={onClickHomeDrawer}>
                  <HomeIcon
                    sx={{
                      mr: 1,
                      fontSize: "1.6rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <ListItemText
                    primary="Home"
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => goToViolationDrawer(PATH.ABOUTID)}
                >
                  <InfoIcon
                    sx={{
                      mr: 1,
                      fontSize: "1.6rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <ListItemText
                    primary="About"
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => goToViolationDrawer(PATH.PRODUCTID)}
                >
                  <InventoryIcon
                    sx={{
                      mr: 1,
                      fontSize: "1.6rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <ListItemText
                    primary="Product"
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton onClick={() => goToViolationDrawer(PATH.ICO)}>
                  <InventoryIcon
                    sx={{
                      mr: 1,
                      fontSize: "1.6rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <ListItemText
                    primary="NFT"
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => goToViolationDrawer(PATH.MEMBERSHIPID)}
                >
                  <LoyaltyIcon
                    sx={{
                      mr: 1,
                      fontSize: "1.6rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <ListItemText
                    primary="Membership"
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => goToViolationDrawer(PATH.CONTACTID)}
                >
                  <CallIcon
                    sx={{
                      mr: 1,
                      fontSize: "1.6rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <ListItemText
                    primary="Contact"
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleDownload()}>
                  <CardTravelIcon
                    sx={{
                      mr: 1,
                      fontSize: "1.6rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <ListItemText
                    primary="View Plan"
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                </ListItemButton>
              </ListItem>
              {LoginUser_Data && (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate(PATH.MYPLAN)}>
                    <CardTravelIcon
                      sx={{
                        mr: 1,
                        fontSize: "1.6rem",
                        color: (theme) => theme.customColors.whiteLight,
                      }}
                    />
                    <ListItemText
                      primary="My Plan"
                      sx={{
                        color: (theme) => theme.customColors.whiteLight,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {LoginUser_Data && (
                <ListItem disablePadding>
                  <ListItemButton onClick={handleLogout}>
                    <LogoutIcon
                      sx={{
                        mr: 1,
                        fontSize: "1.6rem",
                        color: (theme) => theme.customColors.whiteLight,
                      }}
                    />
                    <ListItemText
                      primary="LogOut"
                      sx={{
                        color: (theme) => theme.customColors.whiteLight,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Box>
          <Box>
            <Stack
              direction="row"
              gap={2}
              sx={{
                py: 2,
                backgroundColor: (theme) => theme.customColors.background1,
                boxShadow: 8,
              }}
              justifyContent="center"
            >
              {LoginUser_Data ? (
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  onClick={() => {
                    navigate(PATH.PROFILE);
                    setState({ ...state, drawer: false });
                  }}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: (theme) => theme.customColors.black,
                    pr: 1,
                    borderRadius: "4px",
                    py: 0.4,
                  }}
                >
                  <PersonIcon
                    sx={{
                      fontSize: "1.7rem",
                      color: (theme) => theme.customColors.whiteLight,
                    }}
                  />
                  <Typography
                    sx={{
                      mb: "-1px",
                      color: (theme) => theme.customColors.whiteLight,
                      fontSize: "16px",
                      textTransform: "capitalize",
                    }}
                  >
                    {UserProfile_Data?.users?.name?.split(" ")[0]}
                  </Typography>
                </Stack>
              ) : (
                <>
                  <ButtonDarkContained
                    variant="contained"
                    onClick={() => {
                      setState({ ...state, drawer: false });
                      navigate(PATH.LOGIN);
                    }}
                  >
                    Login
                  </ButtonDarkContained>
                  <ButtonOutlined
                    variant="outlined"
                    onClick={() => navigate(PATH.REGISTER)}
                  >
                    Register
                  </ButtonOutlined>
                </>
              )}
            </Stack>
          </Box>
        </Stack>
      </Drawer>

      <Box
        sx={{
          py: 1,
          width: "100%",
        }}
      >
        <Container maxWidth="lg">
          {/* Desktop Navbar */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={10}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Box>
              <Logo onClickHome={onClickHome} />
            </Box>
            <Stack direction="row" alignItems="center" gap={2}>
              <StyledLinkId sx={{ color: "#fff" }} onClick={onClickHome}>
                Home
              </StyledLinkId>
              <StyledLinkId
                sx={{ color: "#fff" }}
                onClick={() => goToViolation(PATH.ABOUTID)}
              >
                About
              </StyledLinkId>
              <StyledLinkId
                sx={{ color: "#fff" }}
                onClick={() => goToViolation(PATH.PRODUCTID)}
              >
                Product
              </StyledLinkId>
              <StyledLinkId
                sx={{ color: "#fff" }}
                onClick={() => goToViolation(PATH.ICO)}
              >
                NFT
              </StyledLinkId>
              <StyledLinkId
                sx={{ color: "#fff" }}
                onClick={() => goToViolation(PATH.CONTACTID)}
              >
                Contact
              </StyledLinkId>
              <StyledLinkId
                sx={{ color: "#fff" }}
                onClick={() => handleDownload()}
              >
                View Plan
              </StyledLinkId>

              {LoginUser_Data && (
                <StyledLinkId
                  sx={{ color: "#fff" }}
                  onClick={() => navigate(PATH.MYPLAN)}
                >
                  My Plan
                </StyledLinkId>
              )}
            </Stack>
            {LoginUser_Data ? (
              <NavUserData />
            ) : (
              <Stack direction="row" gap={2}>
                <ButtonLightContained
                  variant="contained"
                  onClick={() => navigate(PATH.LOGIN)}
                >
                  Login
                </ButtonLightContained>
                <ButtonOutlined
                  variant="outlined"
                  onClick={() => navigate(PATH.REGISTER)}
                >
                  Register
                </ButtonOutlined>
              </Stack>
            )}
          </Stack>
          {/* Mobile Navbar */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ display: { xs: "flex", sm: "none" } }}
          >
            <Stack direction="row" gap={{ xs: 0.2, sm: 1 }} alignItems="center">
              <IconButton
                onClick={() => setState({ ...state, drawer: true })}
                sx={{
                  padding: "3px !important",
                }}
              >
                <MenuIcon
                  sx={{
                    fontSize: "2rem",
                    color: "#fff",
                  }}
                />
              </IconButton>
              <Logo onClickHome={onClickHome} />
            </Stack>

            {LoginUser_Data ? (
              <NavUserData />
            ) : (
              <Stack direction="row" gap={1}>
                <ButtonLightContained
                  variant="contained"
                  size="small"
                  onClick={() => navigate(PATH.LOGIN)}
                >
                  Login
                </ButtonLightContained>
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>
      {/* scroll Navbar */}
      <Box
        sx={{
          backgroundColor: "#fff",
          boxShadow: 10,
          py: 1,
          position: "fixed",
          top: state.scroll ? 0 : -100,
          transition: "all 0.6s",
          width: "100%",
          zIndex: (theme) => theme.zIndex.modal,
        }}
      >
        <Container maxWidth="lg">
          {/* Desktop */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={10}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Box>
              <ScrollLogo onClickHome={onClickHome} />
            </Box>
            <Stack direction="row" alignItems="center" gap={2}>
              <StyledLinkId onClick={onClickHome}>Home</StyledLinkId>
              <StyledLinkId onClick={() => goToViolation(PATH.ABOUTID)}>
                About
              </StyledLinkId>
              <StyledLinkId onClick={() => goToViolation(PATH.PRODUCTID)}>
                Product
              </StyledLinkId>
              <StyledLinkId onClick={() => goToViolation(PATH.ICO)}>
                NFT
              </StyledLinkId>
              <StyledLinkId onClick={() => goToViolation(PATH.CONTACTID)}>
                Contact
              </StyledLinkId>
              <StyledLinkId onClick={() => handleDownload()}>
                View Plan
              </StyledLinkId>

              {LoginUser_Data && (
                <StyledLinkId onClick={() => navigate(PATH.MYPLAN)}>
                  My Plan
                </StyledLinkId>
              )}
            </Stack>
            {LoginUser_Data ? (
              <NavUserData black={true} />
            ) : (
              <Stack direction="row" gap={2}>
                <ButtonDarkContained
                  variant="contained"
                  onClick={() => navigate(PATH.LOGIN)}
                >
                  Login
                </ButtonDarkContained>
                <ButtonOutlined
                  variant="outlined"
                  onClick={() => navigate(PATH.REGISTER)}
                >
                  Register
                </ButtonOutlined>
              </Stack>
            )}
          </Stack>
          {/* Mobile */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ display: { xs: "flex", sm: "none" } }}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <IconButton onClick={() => setState({ ...state, drawer: true })}>
                <MenuIcon
                  sx={{
                    fontSize: "2.3rem",
                    color: "#fff",
                  }}
                />
              </IconButton>
              <Logo onClickHome={onClickHome} />
            </Stack>
            {LoginUser_Data ? (
              <NavUserData black={true} />
            ) : (
              <Stack direction="row" gap={1}>
                <ButtonDarkContained
                  variant="contained"
                  size="small"
                  onClick={() => navigate(PATH.LOGIN)}
                >
                  Login
                </ButtonDarkContained>
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>
    </div>
  );
};

export default Navbar;
