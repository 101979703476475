import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Dialog,
  Collapse,
  colors,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import WalletIcon from "@mui/icons-material/Wallet";
import { useDispatch, useSelector } from "react-redux";
import {
  addPaymentMethod,
  cancelWithdraw,
  clearAddPaymentMethod,
  clearCancelWithdraw,
  clearDeletePaymentMethod,
  clearEditPaymentMethod,
  clearUpdateWallet,
  deletePaymentMethod,
  editPaymentMethod,
  showMyPaymentMeathod,
  showMyPlan,
  showMyReferals,
  showMyWithdraw,
  showWalletHistory,
  updateWallet,
  userProfile,
} from "../redux/profileReducer";
import moment from "moment";
import { Add } from "@mui/icons-material";
import {
  ButtonDarkContained,
  CustomTextField,
  Text1,
} from "../styles/styledComponent";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { blue, red } from "@mui/material/colors";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useNavigate } from "react-router-dom";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import PATH from "../constants/PathContstant";

const MyPlan = () => {
  const membershipPlanName = [
    "",
    "BASIC",
    "PREMIUM",
    "SUPER PREMIUM",
    "PARTNER",
  ];
  const icoPlanName = [
    "",
    "1 Hub(100 NFT)",
    "1/2 hub(50 NFT)",
    "1/4 hub(25 NFT)",
    "1/8 hub(12 NFT)",
  ];
  const dispatch = useDispatch();
  const {
    ShowMyPlan_isLoading,
    ShowMyPlan_Data,
    ShowMyPlan_isError,

    showMyPaymentMeathod_isLoading,
    showMyPaymentMeathod_Data,
    showMyPaymentMeathod_isError,

    addPaymentMethod_isLoading,
    addPaymentMethod_Data,
    addPaymentMethod_isError,

    editPaymentMethod_isLoading,
    editPaymentMethod_Data,
    editPaymentMethod_isError,

    showMyWithdraw_isLoading,
    showMyWithdraw_Data,
    showMyWithdraw_isError,

    UserProfile_isLoading,
    UserProfile_Data,
    UserProfile_isError,

    updateWallet_isLoading,
    updateWallet_Data,
    updateWallet_isError,

    deletePaymentMethod_isLoading,
    deletePaymentMethod_Data,
    deletePaymentMethod_isError,

    cancelWithdraw_isLoading,
    cancelWithdraw_Data,
    cancelWithdraw_isError,

    showMyReferals_isLoading,
    showMyReferals_Data,
    showMyReferals_isError,

    showWalletHistory_isLoading,
    showWalletHistory_Data,
    showWalletHistory_isError,
  } = useSelector((store) => store.profileStore);

  const navigate = useNavigate();
  const [state, setState] = useState({
    tabStatus: 0,
    icoData: [],
    membershipData: [],
    referalIcoData: [],
    referalMembershipData: [],
    paymentDialog: false,
    withdrawDialog: false,
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    withdrawAmount: "",
    ifscCode: "",
    edit: false,
    withdrawAmountError: {},
    collapseIndex: "",
    walletType: "",
  });
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleCancelWithdraw = (withdrawid, type) => {
    console.log("in withdrae", withdrawid);
    const formData = new FormData();
    formData.append("withdraw_id", withdrawid);
    formData.append("wallet_type", type);
    dispatch(cancelWithdraw(formData));
  };
  const handleSubmitPayment = () => {
    const formData = new FormData();
    formData.append("account_holder_name", state.accountHolderName);
    formData.append("account_no", state.accountNumber);
    formData.append("bank_name", state.bankName);
    formData.append("ifsc_code", state.ifscCode);

    dispatch(addPaymentMethod(formData));
  };
  const handleOnClickRemove = () => {
    const formData = new FormData();
    formData.append("payment_id", showMyPaymentMeathod_Data?.data[0]?.id);
    dispatch(deletePaymentMethod(formData));
  };
  const handleOnClickEdit = () => {
    setState({
      ...state,
      paymentDialog: true,
      accountHolderName:
        showMyPaymentMeathod_Data?.data[0]?.account_holder_name,
      accountNumber: showMyPaymentMeathod_Data?.data[0]?.account_no,
      bankName: showMyPaymentMeathod_Data?.data[0]?.bank_name,
      ifscCode: showMyPaymentMeathod_Data?.data[0]?.ifsc_code,
      edit: true,
    });
  };

  const handleOnClickUpdate = () => {
    const formData = new FormData();
    formData.append("account_holder_name", state.accountHolderName);
    formData.append("account_no", state.accountNumber);
    formData.append("bank_name", state.bankName);
    formData.append("ifsc_code", state.ifscCode);
    formData.append("payment_id", showMyPaymentMeathod_Data?.data[0]?.id);
    dispatch(editPaymentMethod(formData));
  };

  const handleCloseModal = () => {
    setState({
      ...state,
      paymentDialog: false,
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      ifscCode: "",
      edit: false,
    });
  };

  const handleUpdateWithdraw = () => {
    const err = {};
    if (!state.withdrawAmount.trim()) {
      err.withdrawAmount = "amount required";
    }
    if (state.walletType === "Main Wallet") {
      if (
        Number(state.withdrawAmount) >
        Number(UserProfile_Data?.users?.wallet?.amount)
      ) {
        err.withdrawAmount = "withdraw amount is more than wallet amount";
      }
    } else if (state.walletType === "Reward") {
      if (
        Number(state.withdrawAmount) >
        Number(UserProfile_Data?.users?.wallet?.reward)
      ) {
        err.withdrawAmount = "withdraw amount is more than Reward amount";
      }
    } else if (state.walletType === "Awareness Bonus") {
      if (
        Number(state.withdrawAmount) >
        Number(UserProfile_Data?.users?.wallet?.awareness_bonus)
      ) {
        err.withdrawAmount = "withdraw amount is more than Awareness amount";
      }
    } else if (state.walletType === "Field Bonus") {
      if (
        Number(state.withdrawAmount) >
        Number(UserProfile_Data?.users?.wallet?.field_bonus)
      ) {
        err.withdrawAmount = "withdraw amount is more than Field amount";
      }
    }
    if (Number(state.withdrawAmount) === 0) {
      err.withdrawAmount = "enter valid amount";
    }
    if (Object.keys(err).length === 0) {
      const formData = new FormData();
      formData.append("payment_id", showMyPaymentMeathod_Data?.data[0]?.id);
      formData.append("amount", state.withdrawAmount);
      formData.append("wallet_type", state.walletType);
      dispatch(updateWallet(formData));
      setState({ ...state, withdrawAmountError: {} });
    } else {
      setState({ ...state, withdrawAmountError: err });
    }
  };

  const handleOnClickCollapse = (index, Data) => {
    if (index === state.collapseIndex) {
      setState({ ...state, collapseIndex: "" });
    } else {
      const membership = ShowMyPlan_Data?.data?.filter(
        (item) => item.filter === "membership"
      );
      const ico = ShowMyPlan_Data?.data?.filter(
        (item) => item.filter === "ico"
      );
      setState({
        ...state,
        referalIcoData: ico,
        referalMembershipData: membership,
        collapseIndex: index,
      });
    }
  };
  useEffect(() => {
    if (addPaymentMethod_Data) {
      toast.success("Payment Method Added");
      dispatch(clearAddPaymentMethod());
      handleCloseModal();
      dispatch(showMyPaymentMeathod());
    }
    if (addPaymentMethod_isError) {
      toast.error("something went wrong");
      dispatch(clearAddPaymentMethod());
    }
  }, [addPaymentMethod_Data, addPaymentMethod_isError]);

  useEffect(() => {
    if (cancelWithdraw_Data) {
      toast.success("wallet Updated");
      dispatch(clearCancelWithdraw());
      dispatch(showMyWithdraw());
      dispatch(userProfile());
    }

    if (cancelWithdraw_isError) {
      toast.error("something went wrong");
      dispatch(clearCancelWithdraw());
    }
  }, [cancelWithdraw_Data, cancelWithdraw_isError]);
  useEffect(() => {
    if (updateWallet_Data) {
      toast.success("wallet Updated");
      dispatch(clearUpdateWallet());
      dispatch(userProfile());
      dispatch(showMyWithdraw());
      dispatch(showWalletHistory());
      setState({ ...state, withdrawAmount: "", withdrawDialog: false });
    }

    if (updateWallet_isError) {
      toast.error("something went wrong");
      dispatch(clearUpdateWallet());
    }
  }, [updateWallet_Data, updateWallet_isError]);

  useEffect(() => {
    if (deletePaymentMethod_Data) {
      toast.success("Payment Method Updated");
      dispatch(clearDeletePaymentMethod());
      dispatch(showMyPaymentMeathod());
    }

    if (deletePaymentMethod_isError) {
      toast.error("something went wrong");
      dispatch(clearDeletePaymentMethod());
    }
  }, [deletePaymentMethod_Data, deletePaymentMethod_isError]);

  useEffect(() => {
    if (editPaymentMethod_Data) {
      toast.success("Payment Method Updated");
      dispatch(clearEditPaymentMethod());
      handleCloseModal();
      dispatch(showMyPaymentMeathod());
    }

    if (editPaymentMethod_isError) {
      toast.error("something went wrong");
      dispatch(clearEditPaymentMethod());
    }
  }, [editPaymentMethod_Data, editPaymentMethod_isError]);

  useEffect(() => {
    dispatch(showMyPlan());
    dispatch(showMyPaymentMeathod());
    dispatch(showMyWithdraw());
    dispatch(userProfile());
    dispatch(showMyReferals());
    dispatch(showWalletHistory());
  }, []);

  useEffect(() => {
    if (ShowMyPlan_Data) {
      const membership = ShowMyPlan_Data?.data?.filter(
        (item) => item.filter === "membership"
      );
      const ico = ShowMyPlan_Data?.data?.filter(
        (item) => item.filter === "ico"
      );
      setState({ ...state, icoData: ico, membershipData: membership });
    }
  }, [ShowMyPlan_Data]);
  const shareUrl = `${window.location.origin}${PATH.REGISTER}?referCode=${UserProfile_Data?.users?.owncode}`;
  return (
    <>
      <Dialog
        open={state.withdrawDialog}
        PaperProps={{
          sx: {
            minWidth: { xs: "90%", md: "400px" },
            backgroundColor: (theme) => theme.customColors.background1,
          },
        }}
        onClose={() =>
          setState({
            ...state,
            withdrawAmount: "",
            withdrawDialog: false,
            withdrawAmountError: {
              ...state.withdrawAmountError,
              withdrawAmount: "",
            },
          })
        }
      >
        <Box
          sx={{
            p: 2,

            boxShadow: 6,
          }}
        >
          <Stack direction="row" justifyContent="flex-end">
            <Box
              sx={{
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() =>
                setState({
                  ...state,
                  withdrawDialog: false,
                  withdrawAmount: "",
                })
              }
            >
              <CloseIcon fontSize="2rem" />
            </Box>
          </Stack>
          <Text1 variant="subtitle1">Withdraw Amount</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="type withdraw amount"
            name="withdrawAmount"
            value={state.withdrawAmount}
            onChange={onChange}
            type="number"
          />
          {state.withdrawAmountError.withdrawAmount && (
            <Typography color="error">
              {state.withdrawAmountError.withdrawAmount}
            </Typography>
          )}
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            {updateWallet_isLoading ? (
              <CircularProgress
                sx={{ color: (theme) => theme.customColors.whiteLight }}
              />
            ) : (
              <ButtonDarkContained
                variant="contained"
                onClick={handleUpdateWithdraw}
              >
                Submit
              </ButtonDarkContained>
            )}
          </Stack>
        </Box>
      </Dialog>

      <Dialog
        open={state.paymentDialog}
        PaperProps={{
          sx: {
            minWidth: { xs: "90%", lg: "400px" },
            backgroundColor: (theme) => theme.customColors.background1,
          },
        }}
        onClose={handleCloseModal}
      >
        <Box
          sx={{
            p: 2,

            boxShadow: 6,
          }}
        >
          <Stack direction="row" justifyContent="flex-end">
            <Box
              sx={{
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon fontSize="2rem" />
            </Box>
          </Stack>

          <Typography
            textAlign="center"
            sx={{
              mt: 0.5,
              mb: 1,
              fontSize: { xs: "20px", md: "25px" },
              color: (theme) => theme.customColors.whiteLight,
            }}
          >
            {state.edit ? "Update  Payment Info" : "Add Payment Info"}
          </Typography>
          <Text1 variant="subtitle1">Account Holder Name</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="type your name"
            name="accountHolderName"
            value={state.accountHolderName}
            onChange={onChange}
          />
          <Text1
            variant="subtitle1"
            sx={{
              mt: 1.3,
            }}
          >
            Account Number
          </Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="type your account no"
            name="accountNumber"
            value={state.accountNumber}
            onChange={onChange}
          />
          <Text1
            variant="subtitle1"
            sx={{
              mt: 1.3,
            }}
          >
            Bank Name
          </Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="your account name"
            name="bankName"
            value={state.bankName}
            onChange={onChange}
          />
          <Text1
            variant="subtitle1"
            sx={{
              mt: 1.3,
            }}
          >
            IFSC Code
          </Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="your ifsc code"
            name="ifscCode"
            value={state.ifscCode}
            onChange={onChange}
          />
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            {state.edit ? (
              editPaymentMethod_isLoading ? (
                <CircularProgress
                  sx={{ color: (theme) => theme.customColors.whiteLight }}
                />
              ) : (
                <ButtonDarkContained
                  variant="contained"
                  onClick={handleOnClickUpdate}
                >
                  Update
                </ButtonDarkContained>
              )
            ) : addPaymentMethod_isLoading ? (
              <CircularProgress
                sx={{ color: (theme) => theme.customColors.whiteLight }}
              />
            ) : (
              <ButtonDarkContained
                variant="contained"
                onClick={handleSubmitPayment}
              >
                Submit
              </ButtonDarkContained>
            )}
          </Stack>
        </Box>
      </Dialog>

      <div style={{ minHeight: "100vh" }}>
        <Box sx={{ maxWidth: { xs: "98%", md: "80%" }, m: "auto" }}>
          <IconButton
            sx={{
              backgroundColor: "#fff",
              mt: 1,
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            onClick={() => navigate("/")}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Stack direction="row" sx={{ pt: 3 }}>
            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) =>
                  state.tabStatus === 0 && theme.customColors.textColor,

                cursor: "pointer",
              }}
              onClick={() => setState({ ...state, tabStatus: 0 })}
            >
              <Typography
                sx={{
                  color: (theme) =>
                    state.tabStatus === 0
                      ? "#fff"
                      : theme.customColors.whiteLight,
                }}
                fontSize={{ xs: "15px", md: "25px" }}
                textAlign="center"
              >
                Payment
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) =>
                  state.tabStatus === 1 && theme.customColors.textColor,
                cursor: "pointer",
              }}
              onClick={() => setState({ ...state, tabStatus: 1 })}
            >
              <Typography
                sx={{
                  color: (theme) =>
                    state.tabStatus === 1
                      ? "#fff"
                      : theme.customColors.whiteLight,
                }}
                fontSize={{ xs: "15px", md: "25px" }}
                textAlign="center"
              >
                Wallet
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) =>
                  state.tabStatus === 2 && theme.customColors.textColor,
                cursor: "pointer",
              }}
              onClick={() => setState({ ...state, tabStatus: 2 })}
            >
              <Typography
                sx={{
                  color: (theme) =>
                    state.tabStatus === 2
                      ? "#fff"
                      : theme.customColors.whiteLight,
                }}
                fontSize={{ xs: "15px", md: "25px" }}
                textAlign="center"
              >
                Plan
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) =>
                  state.tabStatus === 3 && theme.customColors.textColor,
                cursor: "pointer",
              }}
              onClick={() => setState({ ...state, tabStatus: 3 })}
            >
              <Typography
                sx={{
                  color: (theme) =>
                    state.tabStatus === 3
                      ? "#fff"
                      : theme.customColors.whiteLight,
                }}
                fontSize={{ xs: "15px", md: "25px" }}
                textAlign="center"
              >
                Referal
              </Typography>
            </Box>
          </Stack>
          {/* Payment */}
          {state.tabStatus === 0 && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.customColors.textColor,
                py: 3,
              }}
            >
              <Stack
                sx={{ width: { xs: "98%", md: "80%" }, m: "auto" }}
                direction="row"
                justifyContent="center"
              >
                {showMyPaymentMeathod_isLoading ? (
                  <Stack direction="row" sx={{ py: 2 }}>
                    <CircularProgress sx={{ color: "#000" }} size={35} />
                  </Stack>
                ) : showMyPaymentMeathod_Data?.data?.length > 0 ? (
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.customColors.black,
                      p: 2,
                      borderRadius: "8px",
                      width: { xs: "100%", md: "400px" },
                    }}
                  >
                    <Typography
                      sx={{ color: (theme) => theme.customColors.whiteLight }}
                      fontSize={{ xs: "20px", md: "25px" }}
                      textAlign="center"
                    >
                      Payment Info
                    </Typography>

                    <Grid sx={{ py: 0.5 }} container>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: (theme) => theme.customColors.whiteLight,
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                          }}
                        >
                          Account Holder Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                            ml: 3,
                          }}
                        >
                          {
                            showMyPaymentMeathod_Data?.data[0]
                              ?.account_holder_name
                          }
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid sx={{ py: 0.5 }} container>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: (theme) => theme.customColors.whiteLight,
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                          }}
                        >
                          Account Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                            ml: 3,
                          }}
                        >
                          {showMyPaymentMeathod_Data?.data[0]?.account_no}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid sx={{ py: 0.5 }} container>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: (theme) => theme.customColors.whiteLight,
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                          }}
                        >
                          IFSC Code
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                            ml: 3,
                          }}
                        >
                          {showMyPaymentMeathod_Data?.data[0]?.ifsc_code}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid sx={{ py: 0.5 }} container>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: (theme) => theme.customColors.whiteLight,
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                          }}
                        >
                          Bank Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: { xs: "13px", md: "17px" },
                            fontWeight: 500,
                            ml: 3,
                          }}
                        >
                          {showMyPaymentMeathod_Data?.data[0]?.bank_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                      sx={{ pt: 1 }}
                    >
                      <Button
                        variant="contained"
                        sx={{ px: 3 }}
                        onClick={handleOnClickEdit}
                      >
                        Edit
                      </Button>
                      {deletePaymentMethod_isLoading ? (
                        <CircularProgress sx={{ color: "#fff" }} />
                      ) : (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleOnClickRemove}
                        >
                          Remove
                        </Button>
                      )}
                    </Stack>
                  </Box>
                ) : (
                  <Stack>
                    <Box>
                      <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() =>
                          setState({ ...state, paymentDialog: true })
                        }
                      >
                        Add Payment Method
                      </Button>
                    </Box>
                  </Stack>
                )}
              </Stack>
              {showWalletHistory_Data?.users?.length > 0 ? (
                <>
                  <Box
                    sx={{
                      px: { xs: 0, md: 2 },
                      width: { xs: "98%", md: "80%" },
                      m: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.customColors.whiteLight,
                        fontSize: { xs: "22px", md: "25px" },
                      }}
                    >
                      Wallet History
                    </Typography>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        py: 1,
                      }}
                    >
                      <Box sx={{ flex: 0.5 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "13px", md: "20px" },
                          }}
                        >
                          Amount
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 0.5 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "13px", md: "20px" },
                          }}
                        >
                          Action
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 0.5 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "13px", md: "20px" },
                          }}
                        >
                          Wallet Type
                        </Typography>
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "13px", md: "20px" },
                          }}
                        >
                          Remark
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "13px", md: "20px" },
                          }}
                        >
                          Date
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: (theme) =>
                          theme.customColors.whiteLight,
                      }}
                    />
                    {showWalletHistory_Data?.users?.map((item, itemIndex) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        key={itemIndex}
                        sx={{
                          py: 1,
                          backgroundColor: (theme) => theme.customColors.black,
                          my: 1,
                        }}
                      >
                        <Box sx={{ flex: 0.5 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "12px", md: "20px" },
                            }}
                          >
                            &#8377; {item.amount}
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 0.5 }}>
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              color:
                                item?.action === "credited" ? "green" : "red",
                              textAlign: "center",
                              fontSize: { xs: "12px", md: "20px" },
                            }}
                          >
                            {item?.action}
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 0.5 }}>
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "12px", md: "20px" },
                            }}
                          >
                            {item?.wallet_type
                              ? item?.wallet_type
                              : "Main Wallet"}
                          </Typography>
                        </Box>

                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "12px", md: "20px" },
                            }}
                          >
                            {item?.remark}
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "12px", md: "20px" },
                            }}
                          >
                            {moment(item.created_at).format("DD-MM-YYYY")}
                          </Typography>
                        </Box>
                      </Stack>
                    ))}
                  </Box>
                </>
              ) : (
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "20px",
                    ml: 5,
                  }}
                >
                  No Wallet history Found
                </Typography>
              )}
            </Box>
          )}

          {/* Wallet */}
          {state.tabStatus === 1 &&
            (showMyWithdraw_isLoading ? (
              <Stack direction="row" sx={{ py: 2 }}>
                <CircularProgress sx={{ color: "#000" }} size={35} />
              </Stack>
            ) : (
              <Box
                sx={{
                  backgroundColor: (theme) => theme.customColors.textColor,
                  py: 2,
                }}
              >
                <Stack
                  direction="row"
                  gap={2}
                  justifyContent="center"
                  sx={{ width: "100%", mt: 2 }}
                  flexWrap="wrap"
                >
                  {/* Wallet Card */}
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.customColors.black,
                      p: 2,
                      borderRadius: "8px",
                      height: "120px",
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction="row" gap={1}>
                      <WalletIcon
                        sx={{
                          color: "#fff",
                          fontSize: "23px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                        }}
                      >
                        Wallet
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      &#8377; {UserProfile_Data?.users?.wallet?.amount}
                    </Typography>
                    <Button
                      onClick={() => {
                        showMyPaymentMeathod_Data?.data[0]?.id
                          ? setState({
                              ...state,
                              withdrawDialog: true,
                              walletType: "Main Wallet",
                            })
                          : setState({ ...state, tabStatus: 0 });
                      }}
                      size="small"
                      variant="contained"
                      color="success"
                      sx={{
                        textTransform: "capitalize",
                        mt: 0.5,
                        letterSpacing: "1px",
                      }}
                    >
                      Withdraw
                    </Button>
                  </Box>
                  {/* Stacking Reward Card */}
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.customColors.black,
                      p: 2,
                      pt: 3,
                      borderRadius: "8px",
                      height: "120px",
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction="row" gap={1}>
                      <EmojiEventsIcon
                        sx={{
                          color: "#fff",
                          fontSize: "23px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                        }}
                      >
                        Stacking Reward
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      &#8377; {UserProfile_Data?.users?.wallet?.reward}
                    </Typography>
                    <Button
                      onClick={() => {
                        showMyPaymentMeathod_Data?.data[0]?.id
                          ? setState({
                              ...state,
                              withdrawDialog: true,
                              walletType: "Reward",
                            })
                          : setState({ ...state, tabStatus: 0 });
                      }}
                      size="small"
                      variant="contained"
                      color="success"
                      sx={{
                        textTransform: "capitalize",
                        mt: 0.5,
                        letterSpacing: "1px",
                      }}
                    >
                      Withdraw
                    </Button>
                  </Box>
                  {/* Awareness bonus */}
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.customColors.black,
                      p: 2,
                      pt: 3,
                      borderRadius: "8px",
                      height: "120px",
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction="row" gap={1}>
                      <EmojiEventsIcon
                        sx={{
                          color: "#fff",
                          fontSize: "23px",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "16px",
                          }}
                        >
                          Awareness Bonus
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "12px",
                            marginTop: "-3px",
                          }}
                        >
                          ( Weekly Withdrawl )
                        </Typography>
                      </Box>
                    </Stack>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      &#8377; {UserProfile_Data?.users?.wallet?.awareness_bonus}
                    </Typography>
                    <Button
                      onClick={() => {
                        showMyPaymentMeathod_Data?.data[0]?.id
                          ? setState({
                              ...state,
                              withdrawDialog: true,
                              walletType: "Awareness Bonus",
                            })
                          : setState({ ...state, tabStatus: 0 });
                      }}
                      size="small"
                      variant="contained"
                      color="success"
                      sx={{
                        textTransform: "capitalize",
                        mt: 0.5,
                        letterSpacing: "1px",
                      }}
                    >
                      Withdraw
                    </Button>
                  </Box>
                  {/* Field Bonus */}
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.customColors.black,
                      p: 2,
                      pt: 3,
                      borderRadius: "8px",
                      height: "120px",
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction="row" gap={1}>
                      <EmojiEventsIcon
                        sx={{
                          color: "#fff",
                          fontSize: "23px",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "16px",
                          }}
                        >
                          Field Bonus
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "12px",
                            marginTop: "-3px",
                          }}
                        >
                          ( Daily Withdrawl )
                        </Typography>
                      </Box>
                    </Stack>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "17px",
                      }}
                    >
                      &#8377;
                      {UserProfile_Data?.users?.wallet?.field_bonus}
                    </Typography>
                    <Button
                      onClick={() => {
                        showMyPaymentMeathod_Data?.data[0]?.id
                          ? setState({
                              ...state,
                              withdrawDialog: true,
                              walletType: "Field Bonus",
                            })
                          : setState({ ...state, tabStatus: 0 });
                      }}
                      size="small"
                      variant="contained"
                      color="success"
                      sx={{
                        textTransform: "capitalize",
                        mt: 0.5,
                        letterSpacing: "1px",
                      }}
                    >
                      Withdraw
                    </Button>
                  </Box>
                </Stack>
                {showMyWithdraw_Data?.data?.length > 0 ? (
                  <>
                    <Box
                      sx={{
                        px: { xs: 0, md: 2 },
                        width: { xs: "98%", md: "80%" },
                        m: "auto",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) => theme.customColors.whiteLight,
                          fontSize: { xs: "25px", md: "30px" },
                          mt: 1,
                        }}
                      >
                        Withdraw History
                      </Typography>

                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          py: 1,
                        }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "13px", md: "20px" },
                            }}
                          >
                            Amount
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "13px", md: "20px" },
                            }}
                          >
                            Account No.
                          </Typography>
                        </Box>

                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "13px", md: "20px" },
                            }}
                          >
                            Date
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "13px", md: "20px" },
                            }}
                          >
                            Wallet Type
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "13px", md: "20px" },
                            }}
                          >
                            Status
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider
                        sx={{
                          backgroundColor: (theme) =>
                            theme.customColors.whiteLight,
                        }}
                      />
                      {showMyWithdraw_Data?.data?.map((item, itemIndex) => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          key={itemIndex}
                          sx={{
                            py: 1,
                            backgroundColor: (theme) =>
                              theme.customColors.black,
                            my: 1,
                          }}
                        >
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              sx={{
                                color: "#fff",
                                textAlign: "center",
                                fontSize: { xs: "12px", md: "20px" },
                              }}
                            >
                              &#8377; {item.amount}
                            </Typography>
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              sx={{
                                color: "#fff",
                                textAlign: "center",
                                fontSize: { xs: "12px", md: "20px" },
                              }}
                            >
                              {item.account_no}
                            </Typography>
                          </Box>

                          <Box sx={{ flex: 1 }}>
                            <Typography
                              sx={{
                                color: "#fff",
                                textAlign: "center",
                                fontSize: { xs: "12px", md: "20px" },
                              }}
                            >
                              {moment(item.created_at).format("DD-MM-YYYY")}
                            </Typography>
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              sx={{
                                color: "#fff",
                                textAlign: "center",
                                fontSize: { xs: "12px", md: "20px" },
                              }}
                            >
                              {item.wallet_type
                                ? item.wallet_type
                                : "Main Wallet"}
                            </Typography>
                          </Box>

                          {Number(item.status) === 0 && (
                            <Stack
                              sx={{ flex: 1 }}
                              direction="row"
                              gap={1}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography
                                sx={{
                                  color: colors.orange[700],
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                Pending
                              </Typography>
                              <Typography
                                onClick={() =>
                                  handleCancelWithdraw(
                                    item?.id,
                                    item?.wallet_type
                                  )
                                }
                                sx={{
                                  color: red[700],
                                  textAlign: "center",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  fontSize: "10px",
                                  "&:hover": {
                                    color: red[900],
                                  },
                                }}
                              >
                                cancel
                              </Typography>
                            </Stack>
                          )}

                          {Number(item.status) === 1 && (
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  color: colors.green[600],
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                Approved
                              </Typography>
                            </Box>
                          )}

                          {Number(item.status) === 2 && (
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  color: colors.red[600],
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                Cancelled
                              </Typography>
                            </Box>
                          )}
                        </Stack>
                      ))}
                    </Box>
                  </>
                ) : (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "20px",
                      ml: 5,
                    }}
                  >
                    No Wallet history Found
                  </Typography>
                )}
              </Box>
            ))}
          {/* Plan */}
          {state.tabStatus === 2 && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.customColors.textColor,
                py: 2,
              }}
            >
              <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto" }}>
                <Typography
                  sx={{
                    color: (theme) => theme.customColors.whiteLight,
                    fontSize: { xs: "17px", md: "25px" },
                  }}
                >
                  Membership Plan
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    py: 1,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Plan Type
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Amount
                    </Typography>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Date
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Status
                    </Typography>
                  </Box>
                </Stack>

                <Divider
                  sx={{
                    backgroundColor: (theme) => theme.customColors.whiteLight,
                  }}
                />
                {state.membershipData.length > 0 ? (
                  state.membershipData?.map((item, itemIndex) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={itemIndex}
                      sx={{
                        py: 1,
                        backgroundColor: (theme) => theme.customColors.black,
                        my: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {membershipPlanName[Number(item?.plan_type)]}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          &#8377; {item?.amount}
                        </Typography>
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {moment(item?.created_at).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {item?.status === "0"
                            ? "Pending"
                            : item?.status === "1"
                            ? "Purchased"
                            : item?.status === "2" && "Rejected"}
                        </Typography>
                      </Box>
                    </Stack>
                  ))
                ) : (
                  <Stack direction="row" justifyContent="center">
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      No Data Found
                    </Typography>
                  </Stack>
                )}
              </Box>

              <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto", mt: 4 }}>
                <Typography
                  sx={{
                    color: (theme) => theme.customColors.whiteLight,
                    fontSize: { xs: "17px", md: "25px" },
                  }}
                >
                  NFT Plan
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    py: 1,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Plan Type
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Amount
                    </Typography>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Date
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Status
                    </Typography>
                  </Box>
                </Stack>

                <Divider
                  sx={{
                    backgroundColor: (theme) => theme.customColors.whiteLight,
                  }}
                />

                {state.icoData.length > 0 ? (
                  state.icoData?.map((item, itemIndex) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={itemIndex}
                      sx={{
                        py: 1,
                        backgroundColor: (theme) => theme.customColors.black,
                        my: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {icoPlanName[Number(item?.plan_type)]}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          &#8377; {item?.amount}
                        </Typography>
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {moment(item?.created_at).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {item?.status === "0"
                            ? "Pending"
                            : item?.status === "1"
                            ? "Purchased"
                            : item?.status === "2"
                            ? "Rejected"
                            : item?.status === "3" && "Rewarded"}
                        </Typography>
                      </Box>
                    </Stack>
                  ))
                ) : (
                  <Stack direction="row" justifyContent="center">
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      No Data Found
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Box>
          )}
          {/* REferal */}
          {state.tabStatus === 3 && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.customColors.textColor,
                py: 2,
              }}
            >
              <Box
                sx={{
                  backgroundColor: (theme) => theme.customColors.textColor,
                  p: 2,
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{ color: (theme) => theme.customColors.whiteLight }}
                  fontSize={{ xs: "20px", md: "25px" }}
                  textAlign="center"
                >
                  Referal Info
                </Typography>
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                justifyContent="center"
              >
                <Stack
                  direction="row"
                  gap={3}
                  justifyContent="center"
                  sx={{ pb: 2 }}
                >
                  <Typography
                    sx={{ color: (theme) => theme.customColors.whiteLight }}
                    fontSize={{ xs: "17px", md: "25px" }}
                    textAlign="center"
                  >
                    Your Referal Key
                  </Typography>
                  <Typography
                    sx={{ color: "#fff" }}
                    fontSize={{ xs: "17px", md: "25px" }}
                    textAlign="center"
                  >
                    {UserProfile_Data?.users?.owncode}
                  </Typography>
                </Stack>

                <IconButton
                  sx={{ mt: "-15px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      UserProfile_Data?.users?.owncode
                    );
                    toast.success("Text Copied");
                  }}
                >
                  <ContentCopyIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Typography
                  fontSize={{
                    xs: "17px",
                    md: "25px",
                  }}
                  sx={{
                    color: (theme) => theme.customColors.whiteLight,
                  }}
                >
                  Share
                </Typography>
                <Stack direction="row" alignItems="center" gap={2}>
                  <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>

                  <TelegramShareButton url={shareUrl}>
                    <TelegramIcon size={40} round={true} />
                  </TelegramShareButton>

                  <Typography
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#fff",
                    }}
                    onClick={() => {
                      toast.success("Link copied");
                      navigator.clipboard.writeText(shareUrl);
                    }}
                  >
                    copy link
                  </Typography>
                </Stack>
              </Stack>
              <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    py: 1,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Name
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Mobile
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                      }}
                    >
                      Action
                    </Typography>
                  </Box>
                </Stack>

                <Divider
                  sx={{
                    backgroundColor: (theme) => theme.customColors.whiteLight,
                  }}
                />
                {showMyReferals_Data?.users?.length > 0 &&
                  showMyReferals_Data?.users?.map((item, itemIndex) => (
                    <Box
                      key={itemIndex}
                      sx={{
                        py: 1,
                        backgroundColor: (theme) => theme.customColors.black,
                        my: 1,
                      }}
                    >
                      <Stack direction="row" alignItems="center">
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "12px", md: "20px" },
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: { xs: "12px", md: "20px" },
                            }}
                          >
                            {item?.mobile}
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            onClick={() =>
                              handleOnClickCollapse(itemIndex, item?.userplans)
                            }
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontSize: { xs: "10px", md: "17px" },
                              "&:hover": {
                                color: blue[700],
                              },
                            }}
                          >
                            view
                          </Typography>
                        </Box>
                      </Stack>
                      <Collapse
                        in={state.collapseIndex === itemIndex ? true : false}
                        sx={{}}
                      >
                        <Box
                          sx={{
                            width: { xs: "98%", md: "90%" },
                            m: "auto",
                            mt: 1,
                            backgroundColor: "rgba(255,255,255,0.3)",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                              py: 1,
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                Plan
                              </Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                Amount
                              </Typography>
                            </Box>

                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                Type
                              </Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                Status
                              </Typography>
                            </Box>
                          </Stack>
                          <Divider
                            sx={{
                              backgroundColor: (theme) =>
                                theme.customColors.whiteLight,
                            }}
                          />
                          {item?.userplans?.length > 0 ? (
                            item?.userplans?.map((res, resIndex) => (
                              <>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  key={resIndex}
                                  sx={{
                                    py: 1,

                                    my: 0.2,
                                  }}
                                >
                                  <Box sx={{ flex: 1 }}>
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        textAlign: "center",
                                        fontSize: { xs: "12px", md: "17px" },
                                      }}
                                    >
                                      {
                                        membershipPlanName[
                                          Number(res?.plan_type)
                                        ]
                                      }
                                    </Typography>
                                  </Box>
                                  <Box sx={{ flex: 1 }}>
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        textAlign: "center",
                                        fontSize: { xs: "12px", md: "17px" },
                                      }}
                                    >
                                      &#8377; {res?.amount}
                                    </Typography>
                                  </Box>

                                  <Box sx={{ flex: 1 }}>
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        textAlign: "center",
                                        textTransform: "uppercase",
                                        fontSize: { xs: "12px", md: "17px" },
                                      }}
                                    >
                                      {res?.filter}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ flex: 1 }}>
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        textAlign: "center",
                                        fontSize: { xs: "12px", md: "17px" },
                                      }}
                                    >
                                      {res?.status === "0"
                                        ? "Pending"
                                        : res?.status === "1"
                                        ? "Purchased"
                                        : res?.status === "2" && "Rejected"}
                                    </Typography>
                                  </Box>
                                </Stack>
                                <Divider
                                  sx={{
                                    backgroundColor: "#000",
                                    px: 2,
                                  }}
                                />
                              </>
                            ))
                          ) : (
                            <Stack direction="row" justifyContent="center">
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: { xs: "12px", md: "20px" },
                                }}
                              >
                                No Data Found
                              </Typography>
                            </Stack>
                          )}
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
              </Box>
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};

export default MyPlan;
