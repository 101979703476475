// ico
import img5 from "../assets/images/2100.jpeg";
import img6 from "../assets/images/4100.jpeg";
import img7 from "../assets/images/8500.jpeg";
import img8 from "../assets/images/15000.jpeg";

export const IcoData = [
  {
    price: 95000,
    img: "/95000.jpeg",
  },
  {
    price: 71000,
    img: "/71000.jpeg",
  },
  {
    price: 65000,
    img: "/65000.jpeg",
  },
  {
    price: 41500,
    img: "/41500.jpeg",
  },
  {
    price: 26200,
    img: "/26200.jpeg",
  },
  {
    price: 13500,
    img: "/13500.jpeg",
  },
];

export const membershipData = [
  {
    price: 2100,
    img: img5,
  },
  {
    price: 4100,
    img: img6,
  },
  {
    price: 8500,
    img: img7,
  },
  {
    price: 15000,
    img: img8,
  },
];
