import { Stack, Typography } from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import PATH from "../constants/PathContstant";
import { useNavigate } from "react-router-dom";
import { logOut } from "../redux/auth";
const NavUserData = ({ black }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { UserProfile_Data } = useSelector((store) => store.profileStore);
  return (
    <>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack
          onClick={() => navigate(PATH.PROFILE)}
          direction="row"
          gap={1}
          alignItems="center"
          sx={{
            cursor: "pointer",
            backgroundColor: "#9567f7",
            pr: 1,
            borderRadius: "4px",
            py: 0.4,
            transition: "all 0.1s",
          }}
        >
          <PersonIcon
            sx={{
              fontSize: "1.7rem",
              color: "#fff",
            }}
          />
          <Typography
            sx={{
              mb: "-1px",
              color: "#fff",
              fontSize: "16px",
              textTransform: "capitalize",
            }}
          >
            {UserProfile_Data?.users?.name?.split(" ")[0]}
          </Typography>
        </Stack>
        <Typography
          onClick={() => dispatch(logOut())}
          sx={{
            display: { xs: "none", sm: "block" },
            color: black ? "#000" : "#fff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Logout
        </Typography>
      </Stack>
    </>
  );
};

export default NavUserData;
