import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import img1 from "../assets/images/add-artwork-digital.svg";
import img2 from "../assets/images/item2.svg";
const Card = ({ img, title, subtitle, invert }) => {
  return (
    <Stack
      direction="row"
      sx={{
        background: "linear-gradient(210deg, #37125c 0%, #52158f 100%)",
        height: "150px",
      }}
    >
      <Box
        sx={{
          background:
            "repeating-linear-gradient(45deg, rgba(50, 50, 50, 0.5) 0px, rgba(50, 50, 50, 0.5) 2px, rgba(0, 0, 0, 0.5) 2px, rgba(0, 0, 0, 0.5) 6px)",
          px: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ height: { xs: "60px", md: "90px" } }}>
          <img
            src={img}
            alt="artWork"
            style={{
              filter: !invert ? "invert(1)" : null,
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          px: { xs: 1, lg: 3 },
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontWeight: 700,
            fontSize: { xs: "25px", md: "30px" },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            mt: 2,
            fontWeight: 700,
            fontSize: { xs: "17px", md: "20px" },
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};
const Feature = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to right, #37125c 0%, #9567f7 100%)",
        py: "60px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        gap={2}
        justifyContent="center"
      >
        <Card title="Digital Artworks" subtitle="Buy and Sell." img={img1} />
        <Card
          title="Buy Membership"
          subtitle="Make a unique profit."
          img={img2}
          invert={true}
        />
      </Stack>
    </Box>
  );
};

export default Feature;
