import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import img from "../assets/images/contact.svg";
import SendIcon from "@mui/icons-material/Send";
import {
  ButtonDarkContained,
  CustomTextField,
  Text1,
} from "../styles/styledComponent";
const Contactus = () => {
  return (
    <Box
      sx={{
        py: 2,
        background: "linear-gradient(to right, #37125c 0%, #a994d1 100%)",
      }}
    >
      {/* image with form */}
      <Container max>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{
            color: "#fff",
            fontSize: "40px",
          }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: "#fff" }}
        >
          If you have any query about our membership contact us.
        </Typography>
        <Stack direction={{ xs: "column", md: "row" }}>
          {/* image sec */}
          <Box
            sx={{
              flex: 1,
              height: "60vh",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //     backgroundColor: "red",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: { xs: "100%", md: "90%" },
                py: { xs: 3, md: null },
              }}
            >
              <img
                src={img}
                alt="contact"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
          </Box>
          {/* form sec */}
          <Box
            sx={{
              flex: 1,
              height: "60vh",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "90%" } }}>
              <Text1 variant="subtitle1" sx={{ color: "#fff" }}>
                Full Name
              </Text1>
              <CustomTextField fullWidth size="small" placeholder="full name" />

              <Text1
                variant="subtitle1"
                sx={{
                  mt: 0.5,
                  color: "#fff",
                }}
              >
                Email
              </Text1>
              <CustomTextField
                fullWidth
                size="small"
                placeholder="example <john@gmail.com>"
              />
              <Text1
                variant="subtitle1"
                sx={{
                  mt: 0.5,
                  color: "#fff",
                }}
              >
                Message
              </Text1>
              <CustomTextField
                fullWidth
                size="small"
                multiline
                maxRows={4}
                minRows={3}
                placeholder="type your query..."
              />
              <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                <ButtonDarkContained
                  variant="contained"
                  size="large"
                  endIcon={
                    <SendIcon
                      sx={{ color: (theme) => theme.customColors.whiteLight }}
                    />
                  }
                >
                  Send
                </ButtonDarkContained>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Contactus;
