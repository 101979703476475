import axios from "axios";
const baseURL = "https://apis.artauraa.com/laravelapi/public/api/auth";
const axiosInstance = axios.create({
  baseURL,
});
axiosInstance.interceptors.request.use(async (req) => {
  try {
    let authTokenArtAura = false;
    console.log(localStorage.getItem("authTokenArtAura"));
    if (localStorage.getItem("authTokenArtAura")) {
      authTokenArtAura = JSON.parse(localStorage.getItem("authTokenArtAura"));
    }
    if (authTokenArtAura) {
      req.headers["Authorization"] = `Bearer ${authTokenArtAura?.access_token}`;
      req.headers["Content-Type"] = "application/json";
    }
  } catch (error) {
    console.log(error, "in instance error");
  }
  return req;
});

export default axiosInstance;
