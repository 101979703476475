import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import PATH from "../constants/PathContstant";
import { blue, red } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import {
  ButtonDarkContained,
  CustomTextField,
  RegisterCustomTextField,
  Text1,
} from "../styles/styledComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  clearRegister,
  registerUser,
  sendOtp,
  clearSendOtp,
  clearVerifyOtp,
  verifyOtp,
} from "../redux/auth";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";

const Register = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    refrenceNumber: "",
    error: {},
    mobileNumber: "",
    isOtpSended: "",
    isOtpVerified: "",
    otpDialog: false,
    otp: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    RegisterUser_isLoading,
    RegisterUser_Data,
    RegisterUser_isError,
    sendOtp_isLoading,
    sendOtp_Data,
    sendOtp_isError,
    verifyOtp_isLoading,
    verifyOtp_Data,
    verifyOtp_isError,
  } = useSelector((store) => store.authStore);
  const handleOnChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const handleRegister = () => {
    let err = {};
    let regex = /^[0-9]{10}$/;
    let emailregex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!state.name.trim()) {
      err.name = "Full Name Required";
    }
    if (!state.refrenceNumber.trim()) {
      err.refrenceNumber = "Refrence Number Required";
    } else if (state.refrenceNumber.length < 8) {
      err.refrenceNumber = "Refrence Number must be 8 digits";
    }
    if (!state.mobile.trim()) {
      err.mobile = "Mobile Number is Required";
    } else if (state.mobile.length < 10 || state.mobile.length > 11) {
      err.mobile = "Mobile no Must be 10 digits";
    } else if (!regex.test(state.mobile)) {
      err.mobile = "Mobile no Should be Numbers";
    }

    if (!state.email.trim()) {
      err.email = "Email Id is required";
    } else if (!emailregex.test(state.email)) {
      err.email = "Email Id Not valid";
    }

    if (!state.password.trim()) {
      err.password = "Create Password is required";
    }

    if (!state.confirmPassword.trim()) {
      err.confirmPassword = "Confirm Password is required";
    } else if (state.password !== state.confirmPassword) {
      err.confirmPassword = "Password do not Match";
    }
    if (Object.keys(err).length === 0) {
      dispatch(sendOtp({ mobile: state.mobile }));
      setState({ ...state, error: {}, otpDialog: true });
    } else {
      setState({ ...state, error: err });
    }
  };

  const handleVerifyOtp = () => {
    dispatch(verifyOtp({ mobile: state.mobile, otp: state.otp }));
  };
  const handleRegisterByOtp = () => {
    const formData = new FormData();
    formData.append("mobile", state.mobile);
    formData.append("email", state.email);
    formData.append("name", state.name);
    formData.append("owncode", state.refrenceNumber);
    formData.append("password", state.password);
    formData.append("password_confirmation", state.confirmPassword);
    dispatch(registerUser(formData));
  };
  useEffect(() => {
    const code = searchParams.getAll("referCode");
    if (code && code[0]) {
      setState({ ...state, refrenceNumber: code[0] });
    }
  }, []);

  useEffect(() => {
    if (RegisterUser_Data) {
      toast.success("Registered Successfully");
      dispatch(clearRegister());
      navigate(PATH.LOGIN);
    }
    if (RegisterUser_isError) {
      toast.error("Something Went Wrong");
      dispatch(clearRegister());
    }
  }, [RegisterUser_Data, RegisterUser_isError]);
  useEffect(() => {
    if (sendOtp_Data) {
      toast.success("Otp sended on" + " " + state.mobile);
      dispatch(clearSendOtp());
      setState((prev) => ({ ...prev, isOtpSended: true }));
    }
    if (sendOtp_isError) {
      toast.error(
        sendOtp_isError?.message ? sendOtp_isError?.message : "something wrong"
      );
      dispatch(clearSendOtp());
    }
    if (verifyOtp_Data) {
      toast.success(
        verifyOtp_Data?.message ? verifyOtp_Data : "otp verified successfully !"
      );
      dispatch(clearVerifyOtp());
      handleRegisterByOtp();
      setState((prev) => ({ ...prev, isOtpVerified: true }));
    }
    if (verifyOtp_isError) {
      toast.error(
        verifyOtp_isError?.message
          ? verifyOtp_isError?.message
          : "something wrong"
      );
      dispatch(clearVerifyOtp());
    }
  }, [sendOtp_Data, sendOtp_isError, , verifyOtp_Data, verifyOtp_isError]);

  return (
    <>
      <Dialog
        open={state.otpDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, otpDialog: false, otp: "" }))
        }
        PaperProps={{
          sx: { minWidth: "300px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ px: 2 }}
            justifyContent="space-between"
          >
            <Text1>Register</Text1>
            <IconButton
              onClick={() =>
                setState((prev) => ({ ...prev, otpDialog: false, otp: "" }))
              }
            >
              <Close />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>

        <DialogContent sx={{ mt: 1 }}>
          <Text1>Enter Otp</Text1>
          <CustomTextField
            size="small"
            placeholder="Type otp..."
            fullWidth
            value={state.otp}
            type="number"
            onChange={(e) => {
              if (e.target.value.length < 7) {
                setState((prev) => ({ ...prev, otp: e.target.value }));
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          {verifyOtp_isLoading || RegisterUser_isLoading ? (
            <CircularProgress
              sx={{
                color: (theme) => theme.customColors.whiteLight,
              }}
            />
          ) : (
            <ButtonDarkContained
              onClick={handleVerifyOtp}
              size="large"
              variant="contained"
              disabled={state.otp.length === 6 ? false : true}
              sx={{ textTransform: "capitalize" }}
            >
              Verify Otp
            </ButtonDarkContained>
          )}
        </DialogActions>
      </Dialog>
      <div className="register_root">
        <Box
          sx={{
            p: 2,
            py: 1,
            width: { xs: "330px", lg: "400px" },
            backgroundColor: "#fff",
            boxShadow: 6,
          }}
        >
          <Stack direction="row" justifyContent="flex-end">
            <Box
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <CloseIcon fontSize="1.2rem" />
            </Box>
          </Stack>

          <Typography
            textAlign="center"
            sx={{
              fontSize: "20px",
              color: (theme) => theme.customColors.whiteLight,
            }}
          >
            Register
          </Typography>
          <Text1 variant="subtitle1">Full Name</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="fullname"
            value={state.name}
            name="name"
            onChange={handleOnChange}
          />
          {state.error.name && (
            <Typography sx={{ color: red[600], fontSize: "16px" }}>
              {state.error.name}
            </Typography>
          )}
          <Text1 variant="subtitle1">Email</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="example john@gmail.com"
            value={state.email}
            name="email"
            onChange={handleOnChange}
          />
          {state.error.email && (
            <Typography sx={{ color: red[600], fontSize: "16px" }}>
              {state.error.email}
            </Typography>
          )}
          <Text1 variant="subtitle1">Refrence Number</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="example xawe3453dfe"
            value={state.refrenceNumber}
            name="refrenceNumber"
            onChange={handleOnChange}
          />
          {state.error.refrenceNumber && (
            <Typography sx={{ color: red[600], fontSize: "16px", m: 0 }}>
              {state.error.refrenceNumber}
            </Typography>
          )}
          <Text1 variant="subtitle1">Mobile Number</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="example 999999999"
            value={state.mobile}
            name="mobile"
            onChange={handleOnChange}
          />
          {state.error.mobile && (
            <Typography sx={{ color: red[600], fontSize: "16px" }}>
              {state.error.mobile}
            </Typography>
          )}
          <Text1 variant="subtitle1">Password</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="password"
            value={state.password}
            name="password"
            onChange={handleOnChange}
          />
          {state.error.password && (
            <Typography sx={{ color: red[600], fontSize: "16px" }}>
              {state.error.password}
            </Typography>
          )}
          <Text1 variant="subtitle1">Confirm Password</Text1>
          <CustomTextField
            fullWidth
            size="small"
            placeholder="confirm password"
            value={state.confirmPassword}
            name="confirmPassword"
            onChange={handleOnChange}
          />
          {state.error.confirmPassword && (
            <Typography sx={{ color: red[600], fontSize: "16px" }}>
              {state.error.confirmPassword}
            </Typography>
          )}

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 0.7 }}>
            {sendOtp_isLoading ? (
              <CircularProgress
                sx={{
                  color: (theme) => theme.customColors.whiteLight,
                }}
              />
            ) : (
              <ButtonDarkContained variant="contained" onClick={handleRegister}>
                Register
              </ButtonDarkContained>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={0.7}
            justifyContent="center"
            sx={{ mt: 1.5 }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: (theme) => theme.customColors.whiteLight }}
            >
              Already Have an Account?{" "}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: blue[600], cursor: "pointer" }}
              onClick={() => navigate(PATH.LOGIN)}
            >
              Login
            </Typography>
          </Stack>
        </Box>
      </div>
    </>
  );
};

export default Register;
