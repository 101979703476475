import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { blue } from "@mui/material/colors";
const Card = ({ item }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        cursor: "pointer",
      }}
    >
      <img
        src={item.img}
        alt="productImage"
        style={{ height: "250px", width: "100%", objectFit: "cover" }}
      />
      <Typography
        sx={{
          py: 2,
          textAlign: "center",
          color: (theme) => theme.customColors.white,
        }}
        variant="h6"
      >
        {item.name}
      </Typography>
    </Box>
  );
};
const MostViewed = () => {
  // const productData = [
  //   {
  //     name: "The Madness of Lavapiese",
  //     img: "https://d31cksjl6r6w9h.cloudfront.net/60dee14a510fef5af831d724/media-library/400xauto/Madness-of-Lavapi_7fa187b2110aef.jpg",
  //     price: 3000,
  //   },
  //   {
  //     name: "Creusa Jade",
  //     img: "https://d31cksjl6r6w9h.cloudfront.net/60deca01510fef5af830e84d/media-library/400xauto/8319-Rosie-Mackenzie-026_98317a2b792a1e.jpg",
  //     price: 1000,
  //   },
  //   {
  //     name: "Buffalo Sunset",
  //     img: "https://d31cksjl6r6w9h.cloudfront.net/60ded41e510fef5af83154b7/media-library/400xauto/IMG_E6731_b4087f4f043972.jpg",
  //     price: 2000,
  //   },
  //   {
  //     name: "The River is Wide",
  //     img: "https://d31cksjl6r6w9h.cloudfront.net/60ded41e510fef5af83154b7/media-library/400xauto/IMG_0256_1e6ad4b170fe42.jpg",
  //     price: 2000,
  //   },
  //   {
  //     name: "You and Me",
  //     img: "https://d31cksjl6r6w9h.cloudfront.net/60ded41e510fef5af83154b7/media-library/400xauto/IMG_0256_1e6ad4b170fe42.jpg",
  //     price: 2000,
  //   },
  //   {
  //     name: "Night Dreams",
  //     img: "https://d31cksjl6r6w9h.cloudfront.net/60dee58f510fef5af831fde1/media-library/400xauto/1151-4_874a76a822a3a6.jpg",
  //     price: 2000,
  //   },
  // ];
  const productData = [
    {
      name: "The Madness of Lavapiese",
      img: "/1.jpeg",
      price: 3000,
    },
    {
      name: "Creusa Jade",
      img: "/2.jpeg",
      price: 1000,
    },
    {
      name: "Buffalo Sunset",
      img: "/3.jpeg",
      price: 2000,
    },
    {
      name: "The River is Wide",
      img: "/6.jpeg",
      price: 2000,
    },
    {
      name: "You and Me",
      img: "/7.jpeg",
      price: 2000,
    },
    {
      name: "Night Dreams",
      img: "/8.jpeg",
      price: 2000,
    },
  ];
  return (
    <Box
      sx={{
        background: "linear-gradient(to right, #37125c 0%, #d9caf8 100%)",
        py: 2,
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ color: "#fff", fontSize: "40px" }}
        >
          Most Viewed Products
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: "#fff" }}
        >
          These products are most viewed or more searchable product.
        </Typography>
        <Box sx={{ py: 3 }}>
          <Swiper
            navigation={true}
            spaceBetween={25}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
            }}
            modules={[Navigation, Autoplay]}
          >
            {productData.map((item, index) => (
              <SwiperSlide key={index}>
                <Card item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default MostViewed;
