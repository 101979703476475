import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PATH from "../constants/PathContstant";
import CloseIcon from "@mui/icons-material/Close";
import { blue } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import {
  ButtonDarkContained,
  CustomTextField,
  Text1,
} from "../styles/styledComponent";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/auth";

const Login = () => {
  const dispatch = useDispatch();
  const { LoginUser_isLoading, LoginUser_Data, LoginUser_isError } =
    useSelector((store) => store.authStore);
  const [state, setState] = useState({
    username: "",
    password: "",
    showPassword: false,
  });
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const handleLogin = () => {
    const formData = new FormData();

    if (state.username.includes("@")) {
      formData.append("email", state.username);
    } else {
      formData.append("mobile", state.username);
    }
    formData.append("password", state.password);
    dispatch(loginUser(formData));
  };
  useEffect(() => {
    if (LoginUser_Data) {
      navigate("/");
    }
  }, [LoginUser_Data]);
  return (
    <div className="login_root">
      <Box
        sx={{
          p: 2,
          width: { xs: "330px", lg: "400px" },
          backgroundColor: "#fff",
          boxShadow: 6,
        }}
      >
        <Stack direction="row" justifyContent="flex-end">
          <Box
            sx={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => navigate("/")}
          >
            <CloseIcon fontSize="2rem" />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "#9567f7",
              height: "100px",
              width: "100px",
              borderRadius: "50%",
            }}
          >
            <PersonIcon
              sx={{
                color: "#fff",
                fontSize: "80px",
              }}
            />
          </Stack>
        </Stack>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{
            mt: 0.5,
            mb: 1,
            color: (theme) => theme.customColors.whiteLight,
          }}
        >
          Login
        </Typography>
        <Text1 variant="subtitle1">Username</Text1>
        <CustomTextField
          fullWidth
          size="small"
          placeholder="username"
          name="username"
          value={state.username}
          onChange={onChange}
        />
        <Text1
          variant="subtitle1"
          sx={{
            mt: 1.3,
          }}
        >
          Password
        </Text1>
        <CustomTextField
          fullWidth
          size="small"
          type={state.showPassword ? "text" : "password"}
          placeholder="password"
          name="password"
          value={state.password}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {state.showPassword ? (
                  <RemoveRedEyeIcon
                    onClick={() => setState({ ...state, showPassword: false })}
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setState({ ...state, showPassword: true })}
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
          {LoginUser_isLoading ? (
            <CircularProgress
              sx={{ color: (theme) => theme.customColors.whiteLight }}
            />
          ) : (
            <ButtonDarkContained variant="contained" onClick={handleLogin}>
              Login
            </ButtonDarkContained>
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={0.7}
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.customColors.whiteLight }}
          >
            Don't Have an Account?{" "}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: blue[600], cursor: "pointer" }}
            onClick={() => navigate(PATH.REGISTER)}
          >
            Register
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};

export default Login;
