import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import aboutImage from "../assets/images/about1.jpg";
import { AboutText } from "../styles/styledComponent";
const About = () => {
  return (
    <div>
      <Box sx={{ backgroundColor: "#fff", py: 2 }}>
        <Container maxWidth="lg">
          <Box sx={{ py: 1 }}>
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                color: (theme) => theme.customColors.white,
                fontSize: "40px",
              }}
            >
              About Us
            </Typography>
            <Typography
              variant="subtitle1"
              textAlign="center"
              sx={{ color: (theme) => theme.customColors.whiteLight }}
            >
              We are providing very profitable art work that inspire you.
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              sx={{ mt: 1.5, boxShadow: 4 }}
            >
              <Box sx={{ flex: 1 }}>
                <Box sx={{ height: { xs: "50vh", md: "80vh" } }}>
                  <img
                    src={aboutImage}
                    alt="aboutImag"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  backgroundColor: "#fff",
                  height: { xs: "50vh", md: "80vh" },
                }}
              >
                <Stack
                  alignItems="center"
                  sx={{
                    px: { xs: 2, md: 4 },
                    height: "100%",
                    pb: { xs: 2, md: null },
                  }}
                  direction="row"
                >
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        py: 1.2,
                        color: (theme) => theme.customColors.white,
                      }}
                    >
                      Shooting Stars
                    </Typography>
                    <AboutText variant="subtitle1">
                      We explore your Art across the World and provide
                      opprotunity to give maximum of your work. whether it is
                      your mobile Photography or Art on Canvas.
                    </AboutText>
                    <Typography
                      variant="h4"
                      sx={{
                        py: 1.2,
                        color: (theme) => theme.customColors.white,
                      }}
                    >
                      Assets
                    </Typography>
                    <AboutText variant="subtitle1">
                      Your Abilities and Mobile are assets to capture real art
                      within us we provide a great plateform to encash those
                      abilities to wealth generation.
                    </AboutText>
                    <Typography
                      variant="h4"
                      sx={{
                        py: 1.2,
                        color: (theme) => theme.customColors.white,
                      }}
                    >
                      Endless Possibilities
                    </Typography>
                    <AboutText variant="subtitle1">
                      We provide endless opprotunity in this digital world to
                      encash your art into your wealth and we ensure your status
                      being high on this plateform.
                    </AboutText>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default About;
