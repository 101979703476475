import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../api/axiosInstance";

export const userProfile = createAsyncThunk("userProfile", async (body) => {
  try {
    const { data } = await axiosInstance.get("/showUsersProfile", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const showMyPlan = createAsyncThunk("showMyPlan", async () => {
  try {
    const { data } = await axiosInstance.get("/showMyplanUser");
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const buyPlan = createAsyncThunk("buyPlan", async (body) => {
  try {
    const { data } = await axiosInstance.post("/Buyplan", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});

export const showMyPaymentMeathod = createAsyncThunk(
  "showMyPaymentMeathod",
  async (body) => {
    try {
      const { data } = await axiosInstance.get("/showMyPaymentMeathodUser");
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const addPaymentMethod = createAsyncThunk(
  "addPaymentMethod",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/AddbankAccountUser", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const editPaymentMethod = createAsyncThunk(
  "editPaymentMethod",
  async (body) => {
    try {
      const { data } = await axiosInstance.post(
        "/editMyPaymentMeathodUser",
        body
      );
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const deletePaymentMethod = createAsyncThunk(
  "deletePaymentMethod",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/deletePaymentUser", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const showMyWithdraw = createAsyncThunk(
  "showMyWithdraw",
  async (body) => {
    try {
      const { data } = await axiosInstance.get("/showMyWithdrawlUser");
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const updateWallet = createAsyncThunk("updateWallet", async (body) => {
  try {
    const { data } = await axiosInstance.post("/AddWithdrawlUser", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});

export const cancelWithdraw = createAsyncThunk(
  "cancelWithdraw",
  async (body) => {
    try {
      const { data } = await axiosInstance.post(
        "/changeWithdrawStatusByUser",
        body
      );
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const showMyReferals = createAsyncThunk(
  "showMyReferals",
  async (body) => {
    try {
      const { data } = await axiosInstance.get("/showMyRefralsUser");
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const getImages = createAsyncThunk("getImages", async (body) => {
  try {
    const { data } = await axiosInstance.get("/getUploadImagesUser");
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const uploadImages = createAsyncThunk("uploadImages", async (body) => {
  try {
    const { data } = await axiosInstance.post("/uplodImagesUser", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const showWalletHistory = createAsyncThunk(
  "showWalletHistory",
  async (body) => {
    try {
      const { data } = await axiosInstance.get("/showWalletHistoryUser");
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);

const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState: {
    UserProfile_isLoading: false,
    UserProfile_Data: "",
    UserProfile_isError: "",

    showWalletHistory_isLoading: false,
    showWalletHistory_Data: "",
    showWalletHistory_isError: "",

    ShowMyPlan_isLoading: false,
    ShowMyPlan_Data: "",
    ShowMyPlan_isError: "",

    BuyPlan_isLoading: false,
    BuyPlan_Data: "",
    BuyPlan_isError: "",

    showMyPaymentMeathod_isLoading: false,
    showMyPaymentMeathod_Data: "",
    showMyPaymentMeathod_isError: "",

    addPaymentMethod_isLoading: false,
    addPaymentMethod_Data: "",
    addPaymentMethod_isError: "",

    editPaymentMethod_isLoading: false,
    editPaymentMethod_Data: "",
    editPaymentMethod_isError: "",

    showMyWithdraw_isLoading: false,
    showMyWithdraw_Data: "",
    showMyWithdraw_isError: "",

    updateWallet_isLoading: false,
    updateWallet_Data: "",
    updateWallet_isError: "",

    deletePaymentMethod_isLoading: false,
    deletePaymentMethod_Data: "",
    deletePaymentMethod_isError: "",

    cancelWithdraw_isLoading: false,
    cancelWithdraw_Data: "",
    cancelWithdraw_isError: "",

    showMyReferals_isLoading: false,
    showMyReferals_Data: "",
    showMyReferals_isError: "",

    uploadImages_isLoading: false,
    uploadImages_Data: "",
    uploadImages_isError: "",

    getImages_isLoading: false,
    getImages_Data: "",
    getImages_isError: "",
  },
  reducers: {
    clearUploadImages: (state) => {
      state.uploadImages_Data = "";
      state.uploadImages_isLoading = false;
      state.uploadImages_isError = "";
    },
    clearProfile: (state) => {
      state.UserProfile_Data = "";
      state.UserProfile_isLoading = false;
      state.UserProfile_isError = "";
    },
    clearBuyPlan: (state) => {
      state.BuyPlan_isLoading = false;
      state.BuyPlan_Data = "";
      state.BuyPlan_isError = "";
    },
    clearAddPaymentMethod: (state) => {
      state.addPaymentMethod_isLoading = false;
      state.addPaymentMethod_Data = "";
      state.addPaymentMethod_isError = "";
    },
    clearEditPaymentMethod: (state) => {
      state.editPaymentMethod_isLoading = false;
      state.editPaymentMethod_Data = "";
      state.editPaymentMethod_isError = "";
    },
    clearUpdateWallet: (state) => {
      state.updateWallet_isLoading = false;
      state.updateWallet_Data = "";
      state.updateWallet_isError = "";
    },

    clearDeletePaymentMethod: (state) => {
      state.deletePaymentMethod_isLoading = false;
      state.deletePaymentMethod_Data = "";
      state.deletePaymentMethod_isError = "";
    },
    clearCancelWithdraw: (state) => {
      state.cancelWithdraw_isLoading = false;
      state.cancelWithdraw_Data = "";
      state.cancelWithdraw_isError = "";
    },
  },
  extraReducers: {
    [uploadImages.pending]: (state) => {
      state.uploadImages_isLoading = true;
    },
    [uploadImages.fulfilled]: (state, actions) => {
      state.uploadImages_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.uploadImages_isError = data;
      } else {
        state.uploadImages_Data = data;
        state.uploadImages_isError = "";
      }
    },
    [uploadImages.rejected]: (state) => {
      state.uploadImages_Loading = false;
    },

    [getImages.pending]: (state) => {
      state.getImages_isLoading = true;
    },
    [getImages.fulfilled]: (state, actions) => {
      state.getImages_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.getImages_isError = data;
      } else {
        state.getImages_Data = data;
        state.getImages_isError = "";
      }
    },
    [getImages.rejected]: (state) => {
      state.getImages_Loading = false;
    },

    [showWalletHistory.pending]: (state) => {
      state.showWalletHistory_isLoading = true;
    },
    [showWalletHistory.fulfilled]: (state, actions) => {
      state.showWalletHistory_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showWalletHistory_isError = data;
      } else {
        state.showWalletHistory_Data = data;
        state.showWalletHistory_isError = "";
      }
    },
    [showWalletHistory.rejected]: (state) => {
      state.showWalletHistory_Loading = false;
    },
    [userProfile.pending]: (state) => {
      state.UserProfile_isLoading = true;
    },
    [userProfile.fulfilled]: (state, actions) => {
      state.UserProfile_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.UserProfile_isError = data;
      } else {
        state.UserProfile_Data = data;
        state.UserProfile_isError = "";
      }
    },
    [userProfile.rejected]: (state) => {
      state.UserProfile_Loading = false;
    },

    [showMyPlan.pending]: (state) => {
      state.ShowMyPlan_isLoading = true;
    },
    [showMyPlan.fulfilled]: (state, actions) => {
      state.ShowMyPlan_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.ShowMyPlan_isError = data;
      } else {
        state.ShowMyPlan_Data = data;
        state.ShowMyPlan_isError = "";
      }
    },
    [showMyPlan.rejected]: (state) => {
      state.ShowMyPlan_Loading = false;
    },

    [buyPlan.pending]: (state) => {
      state.BuyPlan_isLoading = true;
    },
    [buyPlan.fulfilled]: (state, actions) => {
      state.BuyPlan_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.BuyPlan_isError = data;
      } else {
        state.BuyPlan_Data = data;
        state.BuyPlan_isError = "";
      }
    },
    [buyPlan.rejected]: (state) => {
      state.BuyPlan_Loading = false;
    },

    [showMyPaymentMeathod.pending]: (state) => {
      state.showMyPaymentMeathod_isLoading = true;
    },
    [showMyPaymentMeathod.fulfilled]: (state, actions) => {
      state.showMyPaymentMeathod_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showMyPaymentMeathod_isError = data;
      } else {
        state.showMyPaymentMeathod_Data = data;
        state.showMyPaymentMeathod_isError = "";
      }
    },
    [showMyPaymentMeathod.rejected]: (state) => {
      state.showMyPaymentMeathod_Loading = false;
    },

    [addPaymentMethod.pending]: (state) => {
      state.addPaymentMethod_isLoading = true;
    },
    [addPaymentMethod.fulfilled]: (state, actions) => {
      state.addPaymentMethod_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.addPaymentMethod_isError = data;
      } else {
        state.addPaymentMethod_Data = data;
        state.addPaymentMethod_isError = "";
      }
    },
    [addPaymentMethod.rejected]: (state) => {
      state.addPaymentMethod_Loading = false;
    },

    [showMyWithdraw.pending]: (state) => {
      state.showMyWithdraw_isLoading = true;
    },
    [showMyWithdraw.fulfilled]: (state, actions) => {
      state.showMyWithdraw_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showMyWithdraw_isError = data;
      } else {
        state.showMyWithdraw_Data = data;
        state.showMyWithdraw_isError = "";
      }
    },
    [showMyWithdraw.rejected]: (state) => {
      state.showMyWithdraw_Loading = false;
    },
    [editPaymentMethod.pending]: (state) => {
      state.editPaymentMethod_isLoading = true;
    },
    [editPaymentMethod.fulfilled]: (state, actions) => {
      state.editPaymentMethod_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.editPaymentMethod_isError = data;
      } else {
        state.editPaymentMethod_Data = data;
        state.editPaymentMethod_isError = "";
      }
    },
    [editPaymentMethod.rejected]: (state) => {
      state.editPaymentMethod_Loading = false;
    },

    [updateWallet.pending]: (state) => {
      state.updateWallet_isLoading = true;
    },
    [updateWallet.fulfilled]: (state, actions) => {
      state.updateWallet_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.updateWallet_isError = data;
      } else {
        state.updateWallet_Data = data;
        state.updateWallet_isError = "";
      }
    },
    [updateWallet.rejected]: (state) => {
      state.updateWallet_Loading = false;
    },

    [deletePaymentMethod.pending]: (state) => {
      state.deletePaymentMethod_isLoading = true;
    },
    [deletePaymentMethod.fulfilled]: (state, actions) => {
      state.deletePaymentMethod_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.deletePaymentMethod_isError = data;
      } else {
        state.deletePaymentMethod_Data = data;
        state.deletePaymentMethod_isError = "";
      }
    },
    [deletePaymentMethod.rejected]: (state) => {
      state.deletePaymentMethod_Loading = false;
    },

    [cancelWithdraw.pending]: (state) => {
      state.cancelWithdraw_isLoading = true;
    },
    [cancelWithdraw.fulfilled]: (state, actions) => {
      state.cancelWithdraw_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.cancelWithdraw_isError = data;
      } else {
        state.cancelWithdraw_Data = data;
        state.cancelWithdraw_isError = "";
      }
    },
    [cancelWithdraw.rejected]: (state) => {
      state.cancelWithdraw_Loading = false;
    },

    [showMyReferals.pending]: (state) => {
      state.showMyReferals_isLoading = true;
    },
    [showMyReferals.fulfilled]: (state, actions) => {
      state.showMyReferals_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showMyReferals_isError = data;
      } else {
        state.showMyReferals_Data = data;
        state.showMyReferals_isError = "";
      }
    },
    [showMyReferals.rejected]: (state) => {
      state.showMyReferals_Loading = false;
    },
  },
});
export default ProfileSlice.reducer;
export const {
  clearProfile,
  clearBuyPlan,
  clearAddPaymentMethod,
  clearEditPaymentMethod,
  clearUpdateWallet,
  clearDeletePaymentMethod,
  clearCancelWithdraw,
  clearUploadImages,
} = ProfileSlice.actions;
