import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearUploadImages,
  getImages,
  uploadImages,
  userProfile,
} from "../redux/profileReducer";
import { toast } from "react-toastify";
import {
  ButtonDarkContained,
  CustomTextField,
  Text1,
} from "../styles/styledComponent";
import { changePassword, clearChangePassword, logOut } from "../redux/auth";
const MyProfile = () => {
  const {
    changePassword_isLoading,
    changePassword_Data,
    changePassword_isError,
  } = useSelector((store) => store.authStore);
  const {
    UserProfile_isLoading,
    UserProfile_Data,
    UserProfile_isError,

    uploadImages_isLoading,
    uploadImages_Data,
    uploadImages_isError,

    getImages_isLoading,
    getImages_Data,
    getImages_isError,
  } = useSelector((store) => store.profileStore);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    dialog: false,
    oldPassword: "",
    oldPasswordType: false,
    newPassword: "",
    newPasswordType: false,
    confirmPassword: "",
    confirmPasswordType: false,
    err: {},
  });
  const handleFileChange = (e) => {
    const formData = new FormData();
    formData.append("title", "image");
    formData.append("image", e.target.files[0]);
    dispatch(uploadImages(formData));
  };
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    let err = {};
    if (!state.oldPassword.trim()) {
      err.oldPassword = "Old Password required";
    }
    if (!state.newPassword.trim()) {
      err.newPassword = "New password required";
    }
    if (!state.confirmPassword.trim()) {
      err.confirmPassword = "Confirm password required";
    } else if (state.confirmPassword !== state.newPassword) {
      err.confirmPassword = "Password do not match";
    }
    if (Object.keys(err).length === 0) {
      const formData = new FormData();
      formData.append("old_password", state.oldPassword);
      formData.append("newpassword", state.newPassword);
      formData.append("newpassword_confirmation", state.confirmPassword);
      dispatch(changePassword(formData));
    } else {
      setState({ ...state, err });
    }
  };
  useEffect(() => {
    if (changePassword_Data) {
      toast.success("Password Change Successfully");
      dispatch(clearChangePassword());
      dispatch(logOut());
      navigate("/");
    }
    if (changePassword_isError) {
      toast.error("something went wrong");
      dispatch(clearChangePassword());
    }
  }, [changePassword_Data, changePassword_isError]);
  useEffect(() => {
    if (uploadImages_Data) {
      toast.success("image uploaded");
      dispatch(clearUploadImages());
      dispatch(getImages());
    }
    if (uploadImages_isError) {
      toast.error("something went wrong");
      dispatch(clearUploadImages());
    }
  }, [uploadImages_Data, uploadImages_isError]);

  useEffect(() => {
    dispatch(userProfile());
    dispatch(getImages());
  }, []);

  return (
    <>
      <Dialog
        open={state.dialog}
        onClose={() => setState({ ...state, dialog: false })}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.customColors.textColor,
            width: { xs: "98%", md: "400px" },
            p: 2,
          },
        }}
      >
        <Stack direction="row" justifyContent="flex-end">
          <Box
            sx={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setState({ ...state, dialog: false })}
          >
            <CloseIcon fontSize="2rem" />
          </Box>
        </Stack>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{
            mt: 0.5,
            mb: 1,
            color: (theme) => theme.customColors.whiteLight,
          }}
        >
          Change Password
        </Typography>
        <Text1 variant="subtitle1">Old Password</Text1>
        <CustomTextField
          fullWidth
          size="small"
          type={state.oldPasswordType ? "text" : "password"}
          placeholder="password"
          name="oldPassword"
          value={state.oldPassword}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {state.oldPasswordType ? (
                  <RemoveRedEyeIcon
                    onClick={() =>
                      setState({ ...state, oldPasswordType: false })
                    }
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() =>
                      setState({ ...state, oldPasswordType: true })
                    }
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {state.err.oldPassword && (
          <Typography sx={{ color: "red" }}>{state.err.oldPassword}</Typography>
        )}
        <Text1 variant="subtitle1">New Password</Text1>
        <CustomTextField
          fullWidth
          size="small"
          type={state.newPasswordType ? "text" : "password"}
          placeholder="password"
          name="newPassword"
          value={state.newPassword}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {state.newPasswordType ? (
                  <RemoveRedEyeIcon
                    onClick={() =>
                      setState({ ...state, newPasswordType: false })
                    }
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() =>
                      setState({ ...state, newPasswordType: true })
                    }
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {state.err.newPassword && (
          <Typography sx={{ color: "red" }}>{state.err.newPassword}</Typography>
        )}
        <Text1 variant="subtitle1">Confirm New Password</Text1>
        <CustomTextField
          fullWidth
          size="small"
          type={state.confirmPasswordType ? "text" : "password"}
          placeholder="password"
          name="confirmPassword"
          value={state.confirmPassword}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {state.confirmPasswordType ? (
                  <RemoveRedEyeIcon
                    onClick={() =>
                      setState({ ...state, confirmPasswordType: false })
                    }
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() =>
                      setState({ ...state, confirmPasswordType: true })
                    }
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      cursor: "pointer",
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {state.err.confirmPassword && (
          <Typography sx={{ color: "red" }}>
            {state.err.confirmPassword}
          </Typography>
        )}
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
          {changePassword_isLoading ? (
            <CircularProgress
              sx={{ color: (theme) => theme.customColors.whiteLight }}
            />
          ) : (
            <ButtonDarkContained variant="contained" onClick={handleSubmit}>
              Submit
            </ButtonDarkContained>
          )}
        </Stack>
      </Dialog>
      <div style={{ minHeight: "100vh" }}>
        <Box sx={{ maxWidth: { xs: "96%", md: "80%" }, m: "auto" }}>
          <IconButton
            sx={{
              backgroundColor: "#fff",
              mt: 1,
              mb: 2,
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            onClick={() => navigate("/")}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          {UserProfile_isLoading ? (
            <Stack direction="row" sx={{ py: 2 }}>
              <CircularProgress
                sx={{ color: (theme) => theme.customColors.textColor }}
                size={35}
              />
            </Stack>
          ) : (
            <Box
              sx={{
                backgroundColor: (theme) => theme.customColors.textColor,
                p: 2,

                borderRadius: "8px",
                m: "auto",
                width: { xs: "95%", md: "400px" },
              }}
            >
              <Typography
                sx={{ color: (theme) => theme.customColors.whiteLight }}
                fontSize={{ xs: "20px", md: "25px" }}
                textAlign="center"
              >
                Profile
              </Typography>

              <Grid sx={{ py: 1 }} container>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                      ml: 3,
                    }}
                  >
                    {UserProfile_Data?.users?.name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid sx={{ py: 1 }} container>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                    }}
                  >
                    Mobile No
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                      ml: 3,
                    }}
                  >
                    {UserProfile_Data?.users?.mobile}
                  </Typography>
                </Grid>
              </Grid>

              <Grid sx={{ py: 1 }} container>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                    }}
                  >
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                      ml: 3,
                      wordBreak: "break-all",
                    }}
                  >
                    {UserProfile_Data?.users?.email}
                  </Typography>
                </Grid>
              </Grid>

              <Grid sx={{ py: 1 }} container>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                    }}
                  >
                    Referal Code
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                      ml: 3,
                    }}
                  >
                    {UserProfile_Data?.users?.owncode}
                  </Typography>
                </Grid>
              </Grid>

              <Grid sx={{ py: 1 }} container>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: (theme) => theme.customColors.whiteLight,
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                    }}
                  >
                    Wallet Amount
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "13px", md: "17px" },
                      fontWeight: 500,
                      ml: 3,
                    }}
                  >
                    &#8377; {UserProfile_Data?.users?.wallet?.amount}
                  </Typography>
                </Grid>
              </Grid>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={2}
                sx={{ pt: 1 }}
              >
                <Button
                  variant="contained"
                  sx={{ px: 3, textTransform: "capitalize" }}
                  onClick={() => setState({ ...state, dialog: true })}
                >
                  Change Password
                </Button>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={2}
                sx={{ pt: 1 }}
              >
                {uploadImages_isLoading ? (
                  <CircularProgress sx={{ color: "#000" }} />
                ) : (
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Upload Images
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )}
              </Stack>
            </Box>
          )}
          <Typography sx={{}}>Alloted NFT</Typography>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            gap={2}
            sx={{ my: 2 }}
          >
            {/* {getImages_Data?.images?.map((item, itemIndex) => (
              <Box
                sx={{
                  height: "150px",
                  width: "150px",
                  border: "2px solid #fff",
                }}
                key={itemIndex}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={`https://apis.artauraa.com/laravelapi/public/${item?.path}`}
                  alt="uploade image"
                />
              </Box>
            ))} */}
            {getImages_Data?.images?.map((item, itemIndex) => (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px 10px 0 0",
                  width: "220px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
                key={itemIndex}
              >
                <img
                  alt="userImage"
                  src={`https://apis.artauraa.com/laravelapi/storage/app/public/${item?.path}`}
                  style={{
                    width: "100%",
                    height: "160px",
                    objectFit: "cover",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                {item?.img_path && (
                  <Box sx={{ py: 1, px: 0.5 }}>
                    {item?.img_path?.nft_link && (
                      <Stack
                        direction={"row"}
                        my={0.2}
                        justifyContent={"center"}
                      >
                        <a
                          href={item?.img_path?.nft_link}
                          target="_blank"
                          style={{ textDecoration: "underline", color: "blue" }}
                        >
                          view
                        </a>
                      </Stack>
                    )}
                    <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                      Amount INR:{" "}
                      <span style={{ fontWeight: 600, fontSize: "14px" }}>
                        ₹{item?.img_path?.amount_inr}
                      </span>
                    </Typography>
                    <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                      Amount USD:{" "}
                      <span style={{ fontWeight: 600, fontSize: "14px" }}>
                        ${item?.img_path?.amount_usd}
                      </span>
                    </Typography>
                    <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                      NFT Address:{" "}
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "14px",
                          wordBreak: "break-all",
                        }}
                      >
                        {item?.img_path?.nft_address}
                      </span>
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Stack>
        </Box>
      </div>
    </>
  );
};

export default MyProfile;
