import { CircularProgress, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Navbar } from "../components";
import { IcoData, membershipData } from "../constants/qrcodeData";
import { buyPlan, clearBuyPlan } from "../redux/profileReducer";
import {
  ButtonDarkContained,
  CustomTextField,
  Text1,
} from "../styles/styledComponent";
const BuyPlan = () => {
  const { price, type, filter } = useParams();
  console.log(filter);
  const navigate = useNavigate();
  const { BuyPlan_isLoading, BuyPlan_Data, BuyPlan_isError } = useSelector(
    (store) => store.profileStore
  );
  const dispatch = useDispatch();
  const [state, setState] = useState({
    txnid: "",
    amount: price,
    upiname: "",
    barCode: "",
  });
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("txn_id", state.txnid);
    formData.append("amount", state.amount);
    formData.append("upi_name", state.upiname);
    formData.append("plan_type", type);
    formData.append("filter", filter === "nft" ? "ico" : filter);
    dispatch(buyPlan(formData));
  };
  useEffect(() => {
    if (BuyPlan_Data) {
      toast.success("Transaction Submitted Successfully");
      setState({ ...state, txnid: "", amount: "", upiname: "" });
      dispatch(clearBuyPlan());
      navigate("/");
    }
    if (BuyPlan_isError) {
      toast.error("Something Went Wrong");
      dispatch(clearBuyPlan());
    }
  }, [BuyPlan_Data, BuyPlan_isError]);
  useEffect(() => {
    if (filter === "membership") {
      const filtimg = membershipData.find(
        (item) => item.price === Number(price)
      );
      setState({ ...state, barCode: filtimg.img });
    } else {
      const filtimg = IcoData.find((item) => item.price === Number(price));
      setState({ ...state, barCode: filtimg.img });
    }
  }, []);
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "#fff",
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{
            minWidth: { xs: "90%", sm: "600px" },
            backgroundColor: "#fff",
            border: "2px solid rgba(0,0,0,0.4)",
            borderRadius: "10px",
          }}
        >
          <Stack
            sx={{ flex: 1, py: { xs: 2, sm: "auto" } }}
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt="barcode"
              src={state.barCode}
              style={{ objectFit: "contain", height: "200px", width: "200px" }}
            />
          </Stack>
          <Box
            sx={{
              flex: 1,
              backgroundColor: "#f5f5f5",
              px: { xs: 1.8, sm: 3 },
              py: { xs: 1, sm: 3 },
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <Text1
              variant="h6"
              sx={{ textAlign: "center", my: { xs: 1, sm: 2 } }}
            >
              Transaction Detail
            </Text1>
            <Text1 variant="subtitle1" textTransform="uppercase">
              Transaction Id
            </Text1>
            <CustomTextField
              fullWidth
              size="small"
              placeholder="transaction id"
              name="username"
              value={state.txnid}
              onChange={(e) => setState({ ...state, txnid: e.target.value })}
            />
            <Text1 variant="subtitle1" textTransform="uppercase">
              Amount
            </Text1>
            <CustomTextField
              fullWidth
              size="small"
              placeholder="amount"
              name="username"
              value={state.amount}
              contentEditable={false}
            />
            <Text1 variant="subtitle1" textTransform="uppercase">
              Upi name
            </Text1>
            <CustomTextField
              fullWidth
              size="small"
              placeholder="upi name"
              name="username"
              value={state.upiname}
              onChange={(e) => setState({ ...state, upiname: e.target.value })}
            />
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
              {BuyPlan_isLoading ? (
                <CircularProgress
                  sx={{ color: (theme) => theme.customColors.whiteLight }}
                />
              ) : (
                <ButtonDarkContained variant="contained" onClick={handleSubmit}>
                  Submit
                </ButtonDarkContained>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default BuyPlan;
